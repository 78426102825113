/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { DeleteIcon, EditIcon } from '../AdminIcon'
import { Modal, Table } from 'react-bootstrap'
import { Apiurl, GetLocalStorage, RemoveSession } from '../../../Utils/Utils'
import axios from 'axios'
import Select from 'react-select';
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useNavigate } from 'react-router-dom'
import { addFormFieldData, deleteFormFieldData, getFontFamilyData, getFormFieldsListData, getFormTabSubCategoryListData, getSectionListDataByTabId, getSubCategoryListData, handleEnableDisableFormFieldsData, updateFormFieldData } from '../../../services/api/admin'
const FormFields = () => {
    const navigate = useNavigate();
    const token = GetLocalStorage("userInfo")
    const [enName, setEnName] = useState();
    const [enNameError, setEnNameError] = useState();

    const [hnName, setHnName] = useState();
    const [hnNameError, setHnNameError] = useState();

    // const [inputName, setInputName] = useState();
    // const [inputNameError, setInputNameError] = useState();

    const [inputType, setInputType] = useState();
    const [inputTypeError, setInputTypeError] = useState();

    const [fontFamily, setFontFamily] = useState();
    const [fontFamilyError, setFontFamilyError] = useState();

    const [fontColor, setFontColor] = useState();
    const [fontColorError, setFontColorError] = useState();

    const [fontSize, setFontSize] = useState();
    const [fontSizeError, setFontSizeError] = useState();

    const [inputRequired, setInputRequired] = useState()
    const [inputRequiredError, setInputRequiredError] = useState()

    const [dropdownValues, setDropDownValues] = useState([{ value: '' }])
    const [dropdownValuesError, setDropDownValuesError] = useState()

    const [validation, setValidation] = useState([{ value: '' }])
    const [subCategoryId, setSubCategoryId] = useState()
    const [searchSubCategoryId, setSearchSubCategoryId] = useState()
    const [subCategoryIdError, setSubCategoryIdError] = useState()
    const [formTabId, setFormTabId] = useState()
    const [searchFormTabId, setSearchFormTabId] = useState()

    const [formTabIdError, setFormTabIdError] = useState()
    const [sectionId, setSectionId] = useState()
    const [searchSectionId, setSearchSectionId] = useState()
    const [sectionIdError, setSectionIdError] = useState()
    const [formFields, setFormFields] = useState([])
    const [formFieldsFull, setFormFieldsFull] = useState([])
    const [fontsList, setFontsList] = useState()
    const [sectionList, setSectionList] = useState([])
    const [editData, setEditData] = useState()
    const [subCategoryList, setSubCategoryList] = useState([])
    const [formTabList, setFormTabList] = useState()
    const [searchFormTabList, setSearchFormTabList] = useState()
    const [searchSectionList, setSearchSectionList] = useState()
    const [deleteData, setDeleteData] = useState()

    const handleKeyUpLoginDetails = (item) => {
        validateForm(item);
    };
    //validations
    const validateForm = (item) => {
        let isValid = true;
        if (item === "subCategoryId" || item === "both") {
            if (!subCategoryId) {
                setSubCategoryIdError("Please Select Sub Category");
                isValid = false;
            } else {
                setSubCategoryIdError("");
            }
        }
        if (item === "formTabId" || item === "both") {
            // if (!subCategoryId) {
            //     setSubCategoryIdError("Please Select Sub Category");
            //     isValid = false;
            // }
            if (!formTabId) {
                setFormTabIdError("Please Select Form Tab");
                isValid = false;
            } else {
                setFormTabIdError("");
            }
        }
        if (item === "sectionId" || item === "both") {
            // if (!formTabId) {
            //     setFormTabIdError("Please Select Form Tab");
            //     isValid = false;
            // }
            if (!sectionId) {
                setSectionIdError("Please Select Section");
                isValid = false;
            } else {
                setSectionIdError("");
            }
        }
        if (item === "enName" || item === "all") {
            // if (!sectionId) {
            //     setSectionIdError("Please Select Section");
            //     isValid = false;
            // }
            if (!enName || enName.trim() === "") {
                setEnNameError("Please Enter English Name");
                isValid = false;
            } else {
                setEnNameError("");
            }
        }

        if (item === "hnName" || item === "all") {
            // if (!enName || enName.trim() === "") {
            //     setEnNameError("Please Enter English Name");
            //     isValid = false;
            // }
            if (!hnName || hnName.trim() === "") {
                setHnNameError("Please Enter Hindi Name");
                isValid = false;
            } else {
                setHnNameError("");
            }
        }

        // if (item === "inputName" || item === "all") {
        //     const inputNameRegex = /^[a-z_]+$/;
        //     if (!hnName || hnName.trim() === "") {
        //         setHnNameError("Please Enter Hindi Name");
        //         isValid = false;
        //     }
        //     if (!inputName || inputName.trim() === "") {
        //         setInputNameError("Please Enter Input Name");
        //         isValid = false;
        //     } else if (!inputNameRegex.test(inputName)) {
        //         setInputNameError("Input Name must contain only lowercase letters and underscores");
        //         isValid = false;
        //     } else {
        //         setInputNameError("");
        //     }
        // }

        if (item === "fontFamily" || item === "all") {
            // if (!inputName || inputName.trim() === "") {
            //     setInputNameError("Please Enter Input Name");
            //     isValid = false;
            // }
            // if (!hnName || hnName.trim() === "") {
            //     setHnNameError("Please Enter Hindi Name");
            //     isValid = false;
            // }
            if (!fontFamily) {
                setFontFamilyError("Please Select Font Family");
                isValid = false;
            } else {
                setFontFamilyError("");
            }
        }

        if (item === "fontColor" || item === "all") {
            // if (!fontFamily) {
            //     setFontFamilyError("Please Select Font Family");
            //     isValid = false;
            // }
            if (!fontColor || fontColor.trim() === "") {
                setFontColorError("Please Select Font Color");
                isValid = false;
            } else {
                setFontColorError("");
            }
        }

        if (item === "fontSize" || item === "all") {
            // if (!fontColor || fontColor.trim() === "") {
            //     setFontColorError("Please Select Font Color");
            //     isValid = false;
            // }
            if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
                setFontSizeError("Please Enter Valid Font Size");
                isValid = false;
            } else {
                setFontSizeError("");
            }
        }
        if (item === "inputType" || item === "all") {
            // if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
            //     setFontSizeError("Please Enter Valid Font Size");
            //     isValid = false;
            // }
            if (!inputType) {
                setInputTypeError("Please Select Input Type");
                isValid = false;
            } else {
                setInputTypeError("");
            }
        }
        if (item === "dropdown" || item === "all") {
            if (inputType?.value == "dropdown" && dropdownValues?.length < 2) {
                setDropDownValuesError("Drop down must contain atleast two values")
            } else {
                setDropDownValuesError("")
            }
        }
        if (item === "required" || item === "all") {
            // if (!inputType) {
            //     setInputTypeError("Please Select Input Type");
            //     isValid = false;
            // }
            if (inputType?.value == "dropdown" && dropdownValues?.length < 2) {
                setDropDownValuesError("Drop down must contain atleast two values")
            }
            if (!inputRequired) {
                setInputRequiredError("Please select Status");
                isValid = false;
            }
            else {
                setInputRequiredError("");
            }
        }
        return isValid;
    };
    useEffect(() => {
        if (inputType?.value == "dropdown" && dropdownValues?.length >= 2) {
            setDropDownValuesError("")
        }
    }, [dropdownValues])
    // add Form field
    const addFormField = async () => {
        if (!validateForm("all")) return
        try {
            const formData = new FormData();
            formData.append("sub_category_id", subCategoryId?.value)
            formData.append("tab_id", formTabId?.value)
            formData.append("section_id", sectionId?.value)
            formData.append("name_en", enName)
            formData.append("name_hi", hnName)
            const inputName = enName?.toLowerCase()?.replaceAll(" ", "_")
            formData.append("input_field_name", inputName)
            formData.append("font_family_id", Number(fontFamily?.value))
            formData.append("font_color", fontColor)
            formData.append("font_size", Number(fontSize))
            formData.append("type", inputType?.value)
            const dropdata = JSON.stringify({ [inputName]: dropdownValues?.map(i => i?.value) });
            formData.append("dropdown_values", dropdata ? dropdata : null)
            const validData = JSON.stringify({ validation: JSON.stringify(validation.map(i => i?.value)) })
            formData.append("validations", validData ? validData : null)
            formData.append("required", inputRequired?.value)
            formData.append("status", "1")
            formData.append("sort", "10")
            await addFormFieldData(formData)

            handleEmptyData()
            getFormFieldsList()
        } catch (error) {
            console.error(error);
        }
    }
    // sub category list
    const getSubCategoryList = async () => {
        try {
            const subCategoryData = await getSubCategoryListData()
            const data = subCategoryData?.data?.map(i => ({
                label: i?.category_name, value: i?.id
            }))

            setSubCategoryList(data)

        } catch (error) {
            console.log("error on sub category list: ", error)
            navigate("/")
        }
    };
    // tab list
    const getFormTabList = async (item) => {
        const formData = new FormData();
        if (item == "search") {
            formData.append("category_id", searchSubCategoryId.value)
        } else {
            formData.append("category_id", subCategoryId.value)
        }
        await getFormTabSubCategoryListData(formData)
            .then((e) => {
                const data = e?.data?.map(i => ({
                    label: i?.name_en, value: i?.id
                }))
                if (item == "search") {
                    setSearchFormTabList(data)
                } else {
                    setFormTabList(data)
                }

            })
            .catch((error) => {
                navigate("/")
            });
    };
    // section list
    const getSectionList = async (item) => {
        const formData = new FormData();
        if (item == "search") {
            formData.append("tab_id", searchFormTabId.value)
        } else {
            formData.append("tab_id", formTabId.value)
        }
        await getSectionListDataByTabId(formData)
            .then((e) => {
                const data = e?.data?.map(i => ({
                    label: i?.name_en, value: i?.id
                }))
                if (item == "search") {
                    setSearchSectionList(data)
                } else {

                    setSectionList(data)

                }
                if (editData?.id) {
                    setSectionId(data?.find(i => i?.value == editData?.section_id))
                }

            })
            .catch((error) => {

            });
    };
    //Form fields list
    const getFormFieldsList = async () => {
        try {
            const formFieldsData = await getFormFieldsListData()
            const data = formFieldsData?.data?.filter(i => i?.deleted_at == null)
            if (data.status = 200) {
                setFormFields(data)
                setFormFieldsFull(data)
            }
        } catch (error) {
            console.log("error in formField data fetch: ", error)
            if (error?.code === "ERR_NETWORK") {
                RemoveSession(navigate);

            }
        }
    };
    //get font families
    const getFontFamily = async () => {
        try {
            const fontFamilyData = await getFontFamilyData()
            const data = fontFamilyData?.data?.map(i => ({
                label: i?.name, value: i?.id
            }))

            setFontsList(data)
        } catch (err) {
            console.log("font families error :", err)
        }
    };

    useEffect(() => {
        if (subCategoryId?.value) {
            getFormTabList(null)
        } else if (searchSubCategoryId?.value) {
            console.log(searchSubCategoryId)
            // setSearchFormTabId("")
            // setSearchSectionId("")
            getFormTabList("search")
        }
    }, [subCategoryId, searchSubCategoryId])

    useEffect(() => {
        if (formTabId?.value) {
            getSectionList()
        } else if (searchFormTabId?.value) {
            setSearchSectionId("")
            getSectionList("search")
        }
    }, [formTabId, searchFormTabId])
    useEffect(() => {
        getFontFamily()
        getSubCategoryList()
        getFormFieldsList()
    }, [])

    // delete sub-Category
    const deleteFormField = async (item) => {
        try {
            const formData = new FormData();
            formData.append("field_id", item?.id)
            formData.append("section_id", item?.section_id)
            await deleteFormFieldData(formData)
            getFormFieldsList()
        } catch (error) {
            console.error(error);
        }
    }
    //edit sub-category
    const editFormField = (item) => {
        setEditData(item)
        setSubCategoryId(subCategoryList?.find(i => i?.value == item?.category_id))
        // setFormTabId(formTabList?.find(i => i?.value == item?.form_id))
        // setSectionId(sectionList?.find(i => i?.value == item?.section_id))
        setEnName(item?.name_en)
        setHnName(item?.name_hi)
        // setInputName(item?.input_field_name)
        setInputType(inputTypeList?.find(i => i.value == item?.type))
        setFontFamily(fontsList?.find(i => i?.value == item?.font_family_id));
        setFontColor(item?.font_color)
        setFontSize(item?.font_size)
        const validations = item?.validations ? JSON.parse(item?.validations) : null;
        const valid = validations && Array.isArray(validations.validation) ? validations.validation.map(i => ({ value: i })) : "";

        const dropdownValues = item?.dropdown_values ? JSON.parse(item?.dropdown_values) : null;
        const drop = dropdownValues && dropdownValues[item?.input_field_name] && Array.isArray(dropdownValues[item?.input_field_name])
            ? dropdownValues[item?.input_field_name].map(i => ({ value: i }))
            : "";
        setValidation(valid ? valid : [{ value: '' }])
        setDropDownValues(drop ? drop : [{ value: '' }])
        setInputRequired(requiredList?.find(i => i?.value == item?.required))
        window.scrollTo(0, 0);
    }
    //update sub-category
    const updateFormField = async () => {
        if (!validateForm("all")) return
        try {
            const formData = new FormData();
            formData.append("sub_category_id", subCategoryId?.value)
            formData.append("tab_id", formTabId?.value)
            formData.append("section_id", sectionId?.value)
            formData.append("field_id", editData?.id)
            formData.append("name_en", enName)
            formData.append("name_hi", hnName)
            const inputName = editData?.input_field_name
            formData.append("input_field_name", inputName)
            formData.append("font_family_id", Number(fontFamily?.value))
            formData.append("font_color", fontColor)
            formData.append("font_size", Number(fontSize))
            formData.append("type", inputType?.value)
            formData.append("status", 1)
            formData.append("sort", "10")

            const dropdata = JSON.stringify({ [inputName]: dropdownValues?.map(i => i?.value) });
            formData.append("dropdown_values", dropdata ? dropdata : null)
            const validData = JSON.stringify({ validation: JSON.stringify(validation.map(i => i?.value)) })
            formData.append("validations", validData)
            formData.append("required", inputRequired?.value)
            await updateFormFieldData(formData)
            handleEmptyData()
            getFormFieldsList()
        } catch (error) {
            console.error(error);
        }
    }
    const inputTypeList = [
        { label: "Text", value: "text" },
        { label: "Number", value: "number" },
        { label: "Password", value: "password" },
        { label: "File", value: "file" },
        { label: "Dropdown", value: "dropdown" },
        { label: "Calender", value: "calender" },
        { label: "Checkbox", value: "checkbox" },
    ]
    const statusList = [
        { label: "Active", value: "1" },
        { label: "Inactive", value: "0" },
    ]
    const requiredList = [
        { label: "Yes", value: "1" },
        { label: "No", value: "0" },
    ]
    const handleDropdownChange = (index, event) => {
        const values = [...dropdownValues];
        values[index].value = event.target.value;
        setDropDownValues(values);
    };

    const handleDropDownAddInput = () => {
        const values = [...dropdownValues];
        values.push({ value: '' });
        setDropDownValues(values);
    };

    const handleDropDownRemoveInput = (index) => {
        const values = [...dropdownValues];
        values.splice(index, 1);
        setDropDownValues(values);
    };
    const handleValidationChange = (index, event) => {
        const values = [...validation];
        values[index].value = event.target.value;
        setValidation(values);
    };

    const handleValidationAddInput = () => {
        if (validation.length < 5) {
            const values = [...validation];
            values.push({ value: '' });
            setValidation(values);
        }
    };

    const handleValidationRemoveInput = (index) => {
        const values = [...validation];
        values.splice(index, 1);
        setValidation(values);
    };
    const handleEmptyData = () => {
        setSubCategoryId()
        setSubCategoryIdError()
        setFormTabId()
        setFormTabIdError()
        setSectionId()
        setSectionIdError()
        setEnName("")
        setHnName("")
        setInputType("")
        setFontFamily("");
        setFontColor("")
        setFontSize("")
        setValidation([{ value: '' }])
        setDropDownValues([{ value: '' }])
        setInputRequired()
        setEditData()
        setEnNameError("")
        setHnNameError("")
        setInputTypeError("")
        setFontFamilyError("");
        setFontColorError("")
        setFontSizeError("")
        setDropDownValuesError("")
        setInputRequiredError("")
    }
    const handleEnableDisable = async (e, item) => {
        try {
            const formData = new FormData();
            formData.append("field_id", item?.id)
            formData.append("section_id", item?.section_id)
            formData.append("status", e?.value)
            await handleEnableDisableFormFieldsData(formData)
            getFormFieldsList()
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        let data = [...formFieldsFull]
        if (searchSubCategoryId?.value) {
            data = data?.filter(item => item?.category_id == searchSubCategoryId?.value)
        }
        if (searchFormTabId?.value) {
            data = data?.filter(item => item?.form_id == searchFormTabId?.value)
        }
        if (searchSectionId?.value) {
            data = data?.filter(item => item?.section_id == searchSectionId?.value)
        }
        setFormFields(data)

    }, [searchSubCategoryId, searchFormTabId, searchSectionId])

    const resetFilter = () => {
        setSearchFormTabId("");
        setSearchSubCategoryId("");
        setSearchFormTabList([])
        setSearchSectionId("")
        setSearchSectionList([])
        setFormFields(formFieldsFull)
    }

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                <div className="aod-inner">
                    <div className={editData?.id ? "aod-update aod-bottom" : "aod-bottom"}>
                        <div className="aod-head">{editData?.id ? "Update" : "Add"} Form Field</div>
                        <div className="row py-4">
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='lf-label w-100'>
                                        Select Sub Category
                                    </label>
                                    <Select
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        isDisabled={editData?.id}
                                        value={subCategoryId}
                                        options={subCategoryList}
                                        onChange={(e) => { setSubCategoryId(e); setSubCategoryIdError("") }}
                                        placeholder="Select Sub Category"
                                        onMenuOpen={() => handleKeyUpLoginDetails("subCategoryId")}
                                        onMenuClose={() => handleKeyUpLoginDetails("subCategoryId")}
                                    />
                                    {subCategoryIdError && <p className='validate-error'>{subCategoryIdError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='lf-label w-100'>
                                        Select Form Tab
                                    </label>
                                    <Select
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        isDisabled={editData?.id}
                                        value={formTabId}
                                        options={formTabList}
                                        onChange={(e) => { setFormTabId(e); setFormTabIdError("") }}
                                        placeholder="Select Form Tab"
                                        onMenuOpen={() => handleKeyUpLoginDetails("formTabId")}
                                        onMenuClose={() => handleKeyUpLoginDetails("formTabId")}
                                    />
                                    {formTabIdError && <p className='validate-error'>{formTabIdError}</p>}
                                </div>

                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='lf-label w-100'>
                                        Select Section
                                    </label>
                                    <Select
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        isDisabled={editData?.id}
                                        value={sectionId}
                                        options={sectionList}
                                        onChange={(e) => { setSectionId(e); setSectionIdError("") }}
                                        placeholder="Select Section"
                                        onMenuOpen={() => handleKeyUpLoginDetails("sectionId")}
                                        onMenuClose={() => handleKeyUpLoginDetails("sectionId")}
                                    />
                                    {sectionIdError && <p className='validate-error'>{sectionIdError}</p>}
                                </div>

                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>English Name</label>
                                    <input className='w-100' placeholder='Enter English Name' type="text" onKeyUp={() => handleKeyUpLoginDetails("enName")} value={enName} onChange={(e) => setEnName(e?.target?.value)} />
                                    {enNameError && <p className='validate-error'>{enNameError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Hindi Name</label>
                                    <input className='w-100' placeholder='Enter Hindi Name' type="text" onKeyUp={() => handleKeyUpLoginDetails("hnName")} value={hnName} onChange={(e) => setHnName(e?.target?.value)} />
                                    {hnNameError && <p className='validate-error'>{hnNameError}</p>}
                                </div>
                            </div>
                            {/* <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Input Field Name</label>
                                    <input className='w-100' placeholder='Enter Input Field Name' type="text" onKeyUp={() => handleKeyUpLoginDetails("inputName")} value={inputName} onChange={(e) => setInputName(e?.target?.value)} />
                                    {inputNameError && <p className='validate-error'>{inputNameError}</p>}
                                </div>
                            </div> */}
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Family</label>
                                    <Select
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        value={fontFamily}
                                        options={fontsList}
                                        onChange={(e) => { setFontFamily(e); setFontFamilyError("") }}
                                        placeholder="Select Font Family"
                                        onMenuOpen={() => handleKeyUpLoginDetails("fontFamily")}
                                        onMenuClose={() => handleKeyUpLoginDetails("fontFamily")}
                                    />
                                    {fontFamilyError && <p className='validate-error'>{fontFamilyError}</p>}
                                </div>
                            </div>
                            <div className="col-md-6 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Color</label>
                                    <label className='adm-label-color'>
                                        {fontColor ? fontColor : <span>Select Font Color</span>}
                                        <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("color")} onBlur={() => handleKeyUpLoginDetails("color")} value={fontColor} onChange={(e) => setFontColor(e?.target?.value)} />
                                    </label>
                                    {fontColorError && <p className='validate-error'>{fontColorError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Size (in pixels)</label>
                                    <input className='w-100' placeholder='Enter Font Size' type="number" value={fontSize} onKeyUp={() => handleKeyUpLoginDetails("size")}
                                        onKeyDown={(e) => {
                                            if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > 0) {
                                                setFontSize(value);
                                                setFontSizeError("")
                                            } else {
                                                setFontSize("");

                                            }
                                        }} />
                                    {fontSizeError && <p className='validate-error'>{fontSizeError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Input Type</label>
                                    <Select
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        value={inputType}
                                        options={inputTypeList}
                                        onChange={(e) => { setInputType(e); setInputTypeError("") }}
                                        placeholder="Select Input Type"
                                        onMenuOpen={() => handleKeyUpLoginDetails("inputType")}
                                        onMenuClose={() => handleKeyUpLoginDetails("inputType")}
                                    />
                                    {inputTypeError && <p className='validate-error'>{inputTypeError}</p>}
                                </div>
                                {
                                    inputType?.value == "dropdown" ? <>
                                        <div className='suf-input-box aod-form-input'>
                                            <label className='mb-1 w-100'>Dropdown Values</label>
                                        </div>
                                        {dropdownValues?.map((input, index) => (
                                            <div className='row ad-reg-res' key={index}>
                                                <div className="col-md-9">
                                                    <div className='suf-input-box aod-form-input mb-2'>
                                                        <input
                                                            className='w-100'
                                                            placeholder={`Enter Value ${index + 1}`}
                                                            type="text"
                                                            value={input.value}
                                                            onChange={(event) => handleDropdownChange(index, event)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-md-3 p-0 ad-reg-btn'>
                                                    {
                                                        dropdownValues.length > 1 &&
                                                        <span className='ad-reg-delete' onClick={() => handleDropDownRemoveInput(index)}><DeleteIcon /></span>

                                                    }
                                                    {
                                                        dropdownValues.length == index + 1 &&
                                                        <span className='ad-reg-plus' onClick={() => handleDropDownAddInput()}>+</span>

                                                    }
                                                </div>


                                            </div>
                                        ))}
                                        {dropdownValuesError && <p className='validate-error'>{dropdownValuesError}</p>}
                                    </> : null
                                }
                            </div>




                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1'>Input Required</label>
                                    <Select
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        value={inputRequired}
                                        options={requiredList}
                                        onChange={(e) => { setInputRequired(e); setInputRequiredError("") }}
                                        placeholder="Select Input Required"
                                        onMenuOpen={() => handleKeyUpLoginDetails("required")}
                                        onMenuClose={() => handleKeyUpLoginDetails("required")}
                                    />
                                    {inputRequiredError && <p className='validate-error'>{inputRequiredError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Validation Description</label>
                                    {validation.map((input, index) => (
                                        <div className='row ad-reg-res' key={index}>
                                            <div className="col-md-9">
                                                <div className='suf-input-box aod-form-input mb-2'>
                                                    <input
                                                        className='w-100'
                                                        placeholder={`Enter validation ${index + 1}`}
                                                        type="text"
                                                        value={input.value}
                                                        onChange={(event) => handleValidationChange(index, event)}
                                                    />
                                                </div>
                                            </div>

                                            <div className='col-md-3 p-0 ad-reg-btn'>
                                                {
                                                    validation.length > 1 &&
                                                    <span className='ad-reg-delete' onClick={() => handleValidationRemoveInput(index)}><DeleteIcon /></span>

                                                }
                                                {
                                                    validation.length == index + 1 && validation.length < 5 &&
                                                    <span className='ad-reg-plus' onClick={() => handleValidationAddInput()}>+</span>

                                                }
                                            </div>


                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="row ad-cat-row">
                            {
                                editData?.id ?
                                    <>
                                        <div className="col-md-7 gap-4 d-flex">
                                            <button onClick={() => handleEmptyData()} className='suf-can-butt w-auto px-4'>
                                                Cancel
                                            </button>
                                            <button onClick={() => updateFormField()}>
                                                Update Form Field
                                            </button> </div></> :
                                    <div className="col-md-4">
                                        <button onClick={addFormField}>
                                            Add Form Field
                                        </button></div>
                            }

                        </div>

                    </div>
                </div>
                <div className="aod-inner pt-0">
                    <div className="aod-bottom">
                        <div className="aod-head">Form Field List</div>
                        <div className="row py-4">
                            <div className="col-md-3">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <Select
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        value={searchSubCategoryId}
                                        options={subCategoryList}
                                        onChange={(e) => setSearchSubCategoryId(e)}
                                        placeholder="Select Sub Category"
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <Select
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        value={searchFormTabId}
                                        options={searchFormTabList}
                                        onChange={(e) => setSearchFormTabId(e)}
                                        placeholder="Select Form Tab"
                                    />
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <Select
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        value={searchSectionId}
                                        options={searchSectionList}
                                        onChange={(e) => setSearchSectionId(e)}
                                        placeholder="Select Section"
                                    />
                                </div>

                            </div>
                            <div className="col-md-1">
                                <button onClick={resetFilter} className='ad-reset-btn w-auto px-4'>
                                    Reset
                                </button></div>
                        </div>
                        <div className="ad-reg-table">
                            <Table size="sm" className='ad-cat-table'>
                                <thead>
                                    <tr>
                                        <th className='ad-sno'>S No</th>
                                        <th className='ad-sno'>Id</th>
                                        <th className='ad-long'>English Name</th>
                                        <th className='ad-long'>Hindi Name</th>
                                        <th className='ad-long'>Input Field Name</th>
                                        <th className='ad-long'>Font Family</th>
                                        <th className='ad-long'>Font Color</th>
                                        <th className='ad-sno'>Font Size</th>
                                        <th className='ad-long'>Input Type</th>
                                        <th className='ad-long'>Dropdown Values</th>
                                        <th className='ad-long'>Input Required</th>
                                        <th className='ad-long'>Validations</th>
                                        <th className='ad-long'>Status</th>
                                        <th className='ad-long'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        formFields?.map((item, index) => {
                                            const validations = item?.validations ? JSON.parse(item?.validations) : null;
                                            const valid = validations && Array.isArray(validations.validation) ? validations.validation.map(i => i).join(", ") : "";
                                            const dropdownValues = item?.dropdown_values ? JSON.parse(item?.dropdown_values) : null;
                                            const drop = dropdownValues && dropdownValues[item?.input_field_name] && Array.isArray(dropdownValues[item?.input_field_name])
                                                ? dropdownValues[item?.input_field_name].map(i => i).join(",")
                                                : "";
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.id} </td>
                                                    <td>{item?.name_en ? item?.name_en : "--"}</td>
                                                    <td>{item?.name_hi ? item?.name_hi : "--"}</td>
                                                    <td>{item?.input_field_name ? item?.input_field_name : "--"}</td>
                                                    <td>{item?.font_family_id ? fontsList?.find(i => i?.value == item?.font_family_id)?.label : "--"}</td>
                                                    <td>{item?.font_color ? item?.font_color : "--"}</td>
                                                    <td>{item?.font_size ? item?.font_size : "--"}</td>
                                                    <td>{item?.type ? item?.type : "--"}</td>
                                                    <td>{drop ? drop : "--"}</td>
                                                    <td>{item?.required == "1" ? "Yes" : "No"}</td>
                                                    <td>{valid ? valid : "--"}</td>
                                                    <td> <Select
                                                        className='menu_open_select'
                                                        classNamePrefix='ad-menu_open_select_class'
                                                        aria-label="Default select example"
                                                        value={{ label: item?.status == 1 ? "Active" : "Inactive", value: item?.status }}
                                                        options={statusList}
                                                        onChange={(e) => handleEnableDisable(e, item)}
                                                        placeholder="Select Status"
                                                    /></td>
                                                    <td><span className='ad-cat-edit' onClick={() => { editFormField(item) }}><EditIcon /></span><span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span></td>
                                                </tr>
                                            )
                                        }
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you really want to delete this Field ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div className="suf-btn p-2">
                        <button onClick={() => { deleteFormField(deleteData) }}>Yes</button>
                        <button className='suf-can-butt' onClick={() => setDeleteData("")}>No</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </AdminDashboard>
    )
}

export default FormFields
