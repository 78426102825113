import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { getGuidelinesFieldsData, getSubCategoryListData } from '../../../services/api/admin'
import { useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { RemoveSession } from '../../../Utils/Utils';
import './AdminGuildlines.css';

const AdminGuidelineslist = () => {
    const navigate = useNavigate();
    const [guidelineslist, setGuidelineslist] = useState()
    const [subCategoryList, setSubCategoryList] = useState([])

    useEffect(() => {
        const guidlinesData = async () => {
            try {
                const data = await getGuidelinesFieldsData()
                if (data?.status == 200) {
                    console.log(data?.data)
                    setGuidelineslist(data?.data) // Correctly sets the guidelines list
                } else {
                    console.log("error")
                }
            } catch (error) {
                console.log("error on tab list: ", error)
            }
        };
        guidlinesData();
    }, [])

    useEffect(() => {
        const getSubCategoryList = async () => {
            try {
                const subCategoryTabData = await getSubCategoryListData()
                const data = subCategoryTabData?.data?.map(i => ({
                    label: i?.category_name, value: i?.id
                }))

                setSubCategoryList(data)

                // })
            } catch (error) {
                console.log("fetch subcategory list", error)
                if (error?.code === "ERR_NETWORK") {
                    RemoveSession(navigate);

                }
            };
        };
        getSubCategoryList();

    }, [])



    const viewGuidlinesData = (id) => {
        navigate(`/admin/guidelines/view/${id}`);
    }

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                <div className="aod-inner pt-0">
                    <div className="aod-bottom">
                        <div className="row ad-cat-row ">
                            <span className='ad-cat-edit d-flex align-items-center editIconWidth' onClick={() => navigate("/admin/guidelines/view/0")}>Add Guidelines</span>
                        </div>
                        <div className="aod-head">Guidelines List</div>
                        <div className="ad-reg-table">
                            <Table size="sm" className='ad-cat-table mt-4'>
                                <thead>
                                    <tr>
                                        <th className='ad-sno'>S No</th>
                                        <th className='ad-long'>Sub Category Id </th>
                                        <th className='ad-long'>Sub Category Name</th>
                                        <th className='ad-long'>Mandatory Guidelines English</th>
                                        <th className='ad-long'>Mandatory Guidelines Hindi</th>
                                        <th className='ad-long'>Optional Guidelines English</th>
                                        <th className='ad-long'>Optional Guidelines Hindi</th>
                                        <th className='ad-long'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        guidelineslist?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item?.category_id || "--"}</td>
                                                <td>{subCategoryList?.find(i => i.value == item?.category_id)?.label || "--"}</td>
                                                <td>
                                                    {item?.mandatory_guidelines_en
                                                        ? item.mandatory_guidelines_en.length > 8
                                                            ? item.mandatory_guidelines_en.substring(0, 18) + "..."
                                                            : item.mandatory_guidelines_en
                                                        : "--"}
                                                </td>
                                                <td>
                                                    {item?.mandatory_guidelines_hi
                                                        ? item.mandatory_guidelines_hi.length > 8
                                                            ? item.mandatory_guidelines_hi.substring(0, 18) + "..."
                                                            : item.mandatory_guidelines_hi
                                                        : "--"}
                                                </td>
                                                <td>
                                                    {item?.optional_guidelines_en
                                                        ? item.optional_guidelines_en.length > 8
                                                            ? item.optional_guidelines_en.substring(0, 18) + "..."
                                                            : item.optional_guidelines_en
                                                        : "--"}
                                                </td>
                                                <td>
                                                    {item?.optional_guidelines_hi
                                                        ? item.optional_guidelines_hi.length > 8
                                                            ? item.optional_guidelines_hi.substring(0, 18) + "..."
                                                            : item.optional_guidelines_hi
                                                        : "--"}
                                                </td>
                                                <td><button className='ad-cat-edit editIconWidth' onClick={() => { viewGuidlinesData(item?.category_id) }}>View</button></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </AdminDashboard>
    )
}

export default AdminGuidelineslist
