import axios from 'axios';
import { Apiurl, GetLocalStorage } from '../../Utils/Utils';

// Create an Axios instance
const apiInstance = axios.create({
    baseURL: Apiurl()
});

// Interceptor for setting the Authorization header
apiInstance.interceptors.request.use(
    (config) => {
        const token = GetLocalStorage("userInfo")
        config.headers['access-control-allow-origin'] = "*"
        config.headers['content-type'] = "multipart/form-data"
        if (token && config.headers) {
            config.headers.authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Interceptor for handling responses and errors
apiInstance.interceptors.response.use(
    (response) => {
        return response?.data;
    },
    (error) => {
        if (error.response) {
            // Handle different error codes
            switch (error.response.status) {
                case 401:
                    // Handle unauthorized error, e.g., redirect to login
                    console.log('Unauthorized, redirecting to login...');
                    break;
                case 403:
                    // Handle forbidden error
                    console.log('Forbidden access.');
                    break;
                case 404:
                    // Handle not found error
                    console.log('Resource not found.');
                    break;
                case 500:
                    // Handle server error
                    console.log('Server error.');
                    // alert(error.message)
                    break;
                default:
                    // Handle other errors
                    console.log('An error occurred.');
            }
        } else {
            // Handle network errors or other unknown errors
            console.log('Network error or request was cancelled.');
        }
        return Promise.reject(error?.response?.data);
    }
);

export default apiInstance;
