import React, { useEffect, useRef, useState } from 'react';
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import { addGuidelinesFieldsData, deleteGuidelinesFieldsData, getGuidelinesFieldsData, getSubCategoryListData, updateGuidelinesFieldsData } from '../../../services/api/admin';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './AdminGuildlines.css';
import { EditIcon } from '../AdminIcon';
import { Modal } from 'react-bootstrap';
import { BsChevronLeft } from 'react-icons/bs';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']
  ],
};

const formats = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];

const AdminGuidelines = () => {
  const navigate = useNavigate();
  const [subCategoryId, setSubCategoryId] = useState();
  const [subCategoryIdError, setSubCategoryIdError] = useState();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [mandatoryGuidelines_en, setMandatoryGuidelines_en] = useState('');
  const [optionalGuidelines_en, setOptionalGuidelines_en] = useState('');
  const [mandatoryGuidelines_hi, setMandatoryGuidelines_hi] = useState('');
  const [optionalGuidelines_hi, setOptionalGuidelines_hi] = useState('');
  const [guidelinesId, setGuidelinesId] = useState(null)
  const [editIcon, setEditIcon] = useState(false)
  const [deleteData, setDeleteData] = useState(false)
  const { catId } = useParams();
  const { isData, setIsData } = useState(true)

  // useEffect(()=>{

  // },[])

  useEffect(() => {
    const getSubCategoryList = async () => {
      try {
        const subCategoryData = await getSubCategoryListData();
        if (subCategoryData && subCategoryData.status === 200) {
          const data = subCategoryData.data?.map(i => ({
            label: i?.category_name,
            value: i?.id
          }));
          setSubCategoryList(data);
          const matchedSubCategory = data.find(subCategory => subCategory.value === parseInt(catId));

          if (matchedSubCategory) {
            setSubCategoryId(matchedSubCategory);
          }
        }
      } catch (error) {
        console.log("error on sub category list: ", error);
        navigate("/");
      }

    };
    getSubCategoryList();
  }, [catId, navigate]);


  // Fetch guidelines based on subcategoryId
  useEffect(() => {
    if (subCategoryId) {
      getGuidelinesDataBySubcategory();
    }
  }, [subCategoryId]);

  const updateFormField = async () => {
    if (!validateForm("all")) return;
    try {
      const editordata = {
        'mandatoryGuidelines_en': mandatoryGuidelines_en,
        'optionalGuidelines_en': optionalGuidelines_en,
        'mandatoryGuidelines_hi': mandatoryGuidelines_hi,
        'optionalGuidelines_hi': optionalGuidelines_hi
      }
      const formData = new FormData();
      formData.append("guideline_id", guidelinesId)
      formData.append("category_id", subCategoryId?.value);
      formData.append("mandatory_guidelines_en", editordata.mandatoryGuidelines_en);
      formData.append("optional_guidelines_en", editordata.optionalGuidelines_en);
      formData.append("mandatory_guidelines_hi", editordata.mandatoryGuidelines_hi);
      formData.append("optional_guidelines_hi", editordata.optionalGuidelines_hi);
      const updateGuidlines = await updateGuidelinesFieldsData(formData)
      if (updateGuidlines && updateGuidlines.status == 200) {

        setGuidelinesId()
        navigate("/admin/guidelines")
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validateForm = (item) => {
    let isValid = true;
    if (item === "subCategoryId" || item === "both") {
      if (!subCategoryId) {
        setSubCategoryIdError("Please Select Sub Category");
        isValid = false;
      } else {
        setSubCategoryIdError("");
      }
    }

    return isValid;
  };

  const getGuidelinesDataBySubcategory = async () => {
    try {
      const existingData = await getGuidelinesFieldsData();
      if (subCategoryId && existingData.status === 200) {
        const formFieldData = existingData.data?.filter(i => i.category_id == subCategoryId.value);
        if (formFieldData.length > 0) {
          setEditIcon(true)
        } else {
          setEditIcon(false)
        }
        setGuidelinesId(formFieldData[0]?.id)
        setMandatoryGuidelines_en(formFieldData[0]?.mandatory_guidelines_en || '');
        setOptionalGuidelines_en(formFieldData[0]?.optional_guidelines_en || '');
        setMandatoryGuidelines_hi(formFieldData[0]?.mandatory_guidelines_hi || '');
        setOptionalGuidelines_hi(formFieldData[0]?.optional_guidelines_hi || '');

      }
    } catch (error) {
      console.error("Error in fetching data:", error);
    }
  };



  const addFormField = async () => {
    if (!validateForm("all")) return;
    const editordata = {
      'mandatoryGuidelines_en': mandatoryGuidelines_en,
      'optionalGuidelines_en': optionalGuidelines_en,
      'mandatoryGuidelines_hi': mandatoryGuidelines_hi,
      'optionalGuidelines_hi': optionalGuidelines_hi
    }

    try {
      const formData = new FormData();
      formData.append("category_id", subCategoryId?.value);
      formData.append("mandatory_guidelines_en", editordata.mandatoryGuidelines_en);
      formData.append("optional_guidelines_en", editordata.optionalGuidelines_en);
      formData.append("mandatory_guidelines_hi", editordata.mandatoryGuidelines_hi);
      formData.append("optional_guidelines_hi", editordata.optionalGuidelines_hi);
      const addData = await addGuidelinesFieldsData(formData)
      if (addData?.status == 200) {

        setGuidelinesId()
        navigate("/admin/guidelines")
      }
    } catch (error) {
      console.error(error);
    }
  };


  const toggleEdit = () => {
    setEdit(prevEdit => {
      const newEditState = !prevEdit;

      // Fetch existing data after toggling edit state
      if (subCategoryId) {
        getGuidelinesDataBySubcategory();
      }

      return newEditState;
    });
  };

  const deleteFormField = async () => {
    try {
      const payload = {
        guideline_id: guidelinesId,
        category_id: subCategoryId.value,
      }

      const res = await deleteGuidelinesFieldsData(payload)
      console.log(res)
      if (res?.status == 200) {
        setEditIcon(false)
        setMandatoryGuidelines_en('');
        setOptionalGuidelines_en('');
        setMandatoryGuidelines_hi('');
        setOptionalGuidelines_hi('');
        setDeleteData(false)
        navigate("/admin/guidelines")
      }

    } catch (e) {

    }
  }



  return (
    <AdminDashboard>
      <div className='aod-outer'>
        <div className="aod-inner">
          <div className={edit ? "aod-update aod-bottom" : "aod-bottom"}>
            {/* <div className="aod-head">{edit ? "Update" : "Add"} Guidelines</div> */}
            <div className="aod-head text-center adm-head"> <button onClick={() => navigate("/admin/guidelines")}><BsChevronLeft /> Back</button>{edit ? "Update" : "Add"} Guidelines</div>
            <div className="row py-4">
              <div className="col-md-5 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-5' >
                  <label className='lf-label w-100'>
                    Select Sub Category
                  </label>
                  <Select
                    className="menu_open_select"
                    classNamePrefix="ad-menu_open_select_class"
                    aria-label="Default select example"
                    isDisabled={editIcon}
                    value={subCategoryId} // Make sure subCategoryId is the selected object (with label and value)
                    options={subCategoryList}
                    onChange={(selectedOption) => {
                      setEdit(true)
                      setSubCategoryId(selectedOption); // selectedOption contains both label and value
                      setSubCategoryIdError("");
                    }}
                    placeholder="Select Sub Category"
                  />

                  {subCategoryIdError && <p className='validate-error'>{subCategoryIdError}</p>}
                </div>
              </div>
              {
                editIcon && !edit ?
                  <div className="row ad-cat-row ">
                    <span className='ad-cat-edit d-flex align-items-center editIconWidth' onClick={toggleEdit}><EditIcon />Edit Guidelines</span>
                  </div> :
                  null
              }
              <div className='d-flex gap-5  flex-column justify-content-between'>
                <div className='d-flex gap-3 justify-content-between'>
                  <div className='suf-input-box aod-form-input mb-5' >
                    <label className='lf-label w-100'>
                      Mandatory Guidelines English
                    </label>
                    <ReactQuill
                      value={mandatoryGuidelines_en}
                      onChange={setMandatoryGuidelines_en}
                      modules={modules}
                      formats={formats}
                      theme="snow"
                      readOnly={!edit}

                    />
                  </div>
                  <div className='suf-input-box aod-form-input mb-5'>
                    <label className='lf-label w-100'>
                      Optional Guidelines English
                    </label>
                    <ReactQuill
                      value={optionalGuidelines_en}
                      onChange={setOptionalGuidelines_en}
                      modules={modules}
                      formats={formats}
                      theme="snow"
                      readOnly={!edit}

                    />
                  </div>
                </div>
                <div className='d-flex gap-3 justify-content-between'>
                  <div className='suf-input-box aod-form-input mb-5'>
                    <label className='lf-label w-100'>
                      Mandatory Guidelines Hindi
                    </label>
                    <ReactQuill
                      value={mandatoryGuidelines_hi}
                      onChange={setMandatoryGuidelines_hi}
                      modules={modules}
                      formats={formats}
                      theme="snow"
                      readOnly={!edit}

                    />
                  </div>
                  <div className='suf-input-box aod-form-input mb-5'>
                    <label className='lf-label w-100 '>
                      Optional Guidelines Hindi
                    </label>
                    <ReactQuill
                      value={optionalGuidelines_hi}
                      onChange={setOptionalGuidelines_hi}
                      modules={modules}
                      formats={formats}
                      theme="snow"
                      readOnly={!edit}

                    />
                  </div>
                </div>
              </div>
              <div className="row ad-cat-row">

                {
                  edit ?
                    <>
                      <div className="col-md-7 gap-4 d-flex">
                        <button onClick={() => toggleEdit()} className='suf-can-butt w-auto px-4'>
                          Cancel
                        </button>
                        {guidelinesId ?
                          <button onClick={() => updateFormField()}>
                            Update Guidelines
                          </button> :
                          <button onClick={() => addFormField()}>
                            Save Guidelines
                          </button>
                        }
                      </div></> :
                    editIcon ?
                      <button className='danger' onClick={() => setDeleteData(true)}>
                        Delete Guidelines
                      </button> :
                      null
                  // <button onClick={() => toggleEdit()}>
                  //   Add Guidelines
                  // </button>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={deleteData} onHide={() => setDeleteData(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Do you really want to delete this Field ?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <div className="suf-btn p-2">
            <button onClick={deleteFormField}>Yes</button>
            <button className='suf-can-butt' onClick={() => setDeleteData(false)}>No</button>
          </div>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  );
};

export default AdminGuidelines;
