import './App.css';
import Home from './pages/OuterPages/Home/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Auth/Login/Login';
import SignUp from './pages/Auth/SignUp/SignUp';
import ResetPassword from './pages/Auth/ResetPassword/ResetPassword';
import ForgotPassword from './pages/Auth/ForgotPassword/ForgotPassword';
import Dashboard from './pages/Dashboard/Dashboard';

import Guidelines from './pages/Guidelines/Guidelines';
import Application from './pages/Application/Application';
import DashboardCategory from './pages/DashboardCategory/DashboardCategory';
import { useEffect } from 'react';
import { Apiurl } from './Utils/Utils';
import axios from 'axios';
import CategoryInfo from './pages/CategoryInfo/CategoryInfo';
import Category from './pages/Admin/Category/Category';
import SubCategory from './pages/Admin/SubCategory/SubCategory';
import DashboardLayout from './pages/Admin/DashboardLayout/DashboardLayout';
import RegistrationForm from './pages/Admin/RegistrationForm/RegistrationForm';
import ApplicationTabs from './pages/Admin/ApplicationTabs/ApplicationTabs';
import ApplicationSections from './pages/Admin/ApplicationSections/ApplicationSections';
import FormFields from './pages/Admin/FormFields/FormFields';
import Users from './pages/Admin/Users/Users';
import { LanguageProvider } from './components/LanguageProvider/LanguageProvider ';
import AdminLogin from './pages/Admin/AdminLogin/AdminLogin';
import AdminResetPassword from './pages/Admin/AdminResetPassword/AdminResetPassword';
import AdminForgotPassword from './pages/Admin/AdminForgotPassword/AdminForgotPassword';
import RegistrationFormAdd from './pages/Admin/RegistrationForm/RegistrationFormAdd';
import AdminLoginAdd from './pages/Admin/AdminLogin/AdminLoginAdd';
import AdminResetPasswordAdd from './pages/Admin/AdminResetPassword/AdminResetPasswordAdd';
import AdminForgotPasswordAdd from './pages/Admin/AdminForgotPassword/AdminForgotPasswordAdd';
import CategoryAdd from './pages/Admin/Category/CategoryAdd';
import SubCategoryAdd from './pages/Admin/SubCategory/SubCategoryAdd';
import AdminGuidelines from './pages/Admin/AdminGuidelines/AdminGuidelines';
import AdminGuidelineslist from './pages/Admin/AdminGuidelines/AdminGuidelineslist';
function App() {
  const ClearCache = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      url: `${Apiurl()}/clear-cache`,
    };
    axios(option)
      .then((e) => { })
      .catch((error) => { });
  };
  useEffect(() => {
    ClearCache()
  }, [])

  return (
    <LanguageProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/categoryInfo' element={<CategoryInfo />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/guidelines' element={<Guidelines />} />
          <Route path='/category' element={<DashboardCategory />} />
          <Route path='/application/*' element={<Application />} />
          {/* Admin Routes */}
          <Route path='/admin/category' element={<Category />} />
          <Route path='/admin/category/:name' element={<CategoryAdd />} />
          <Route path='/admin/sub-category' element={<SubCategory />} />
          <Route path='/admin/sub-category/:name' element={<SubCategoryAdd />} />
          <Route path='/admin' element={<DashboardLayout />} />
          <Route path='/admin/registration' element={<RegistrationForm />} />
          <Route path='/admin/registration/:name' element={<RegistrationFormAdd />} />
          <Route path='/admin/application-tabs' element={<ApplicationTabs />} />
          <Route path='/admin/application-sections' element={<ApplicationSections />} />
          <Route path='/admin/application-form' element={<FormFields />} />
          <Route path='/admin/users' element={<Users />} />
          <Route path='/admin/login' element={<AdminLogin />} />
          <Route path='/admin/login/:name' element={<AdminLoginAdd />} />
          <Route path='/admin/reset-password' element={<AdminResetPassword />} />
          <Route path='/admin/reset-password/:name' element={<AdminResetPasswordAdd />} />
          <Route path='/admin/forgot-password' element={<AdminForgotPassword />} />
          <Route path='/admin/forgot-password/:name' element={<AdminForgotPasswordAdd />} />
          <Route path='/admin/guidelines/view/:catId' element={<AdminGuidelines />} />
          <Route path='/admin/guidelines' element={<AdminGuidelineslist />} />
        </Routes>
      </BrowserRouter>
    </LanguageProvider>
  );
}
export default App;