import React from 'react'
import { DashboardIcon, RightArrowIcon } from './ApplicationIcon'
import record from "../../assets/images/ao-record.png"
import Dashboard from '../../pages/Dashboard/Dashboard'
import { useNavigate } from 'react-router-dom'
import { GetLocalStorage, SetLocalStorage } from '../../Utils/Utils'
import { useLanguage } from '../LanguageProvider/LanguageProvider '
const Overview = () => {
    const {language,setLanguage}=useLanguage();

    const navigate = useNavigate();
    const getLoginSubCabData = GetLocalStorage("LoginSubCatData");

    const hanldeNavigate = () => {
        if (!getLoginSubCabData) {
            SetLocalStorage("catNotSelect", "true")

            navigate("/category");
        } else {
            navigate("/application/organization-details");

        }
    }
    return (
        <Dashboard first={language ? "होम": "Home"} second={language ? "आवेदन / अवलोकन":"Application / Overview "} head={language ? "आवेदन / अवलोकन":"Application / Overview "}>
            <div className='ao-main'>
               
                <div className="ao-bottom my-4">
                    <div className="ao-bottom-inner">
                        <img src={record} alt="record" />
                        <p>{language ? "कोई रिकॉर्ड नहीं मिला!": "NO RECORD FOUND!"}</p>
                        <p>{language ? "कृपया अपना आवेदन भरना शुरू करें":"Please commence filling out your Application"}</p>
                        <button onClick={() => hanldeNavigate()} >{language ? "भरना शुरू करें" : " Fill Now"} <RightArrowIcon /></button>
                    </div>
                </div>
            </div>
        </Dashboard>
    )
}

export default Overview
