import React, { useEffect, useState } from 'react'
// import { AdventuresTourismUnit, EntertainmentUnit, FoodDrinkUnit, HomeResidentialUnit, OtherUnit, TravelTradeUnit } from '../HomeIcons'
// import homebg from "../../../assets/images/home-cat-bg.png"
// import { CgClipboard } from "react-icons/cg";
import { Apiurl, GetLocalStorage, Imageurl, SetLocalStorageStringify } from '../../../Utils/Utils';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { useLanguage } from '../../LanguageProvider/LanguageProvider ';
import { BtnGray, BtnGreen } from '../HomeIcons';
import { getCategories, getSubCategories } from '../../../services/api/outer';
const HomeCategory = () => {
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate();
  // token from local storage
  const getUserInfo = GetLocalStorage("userInfo");
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [subCategoryData, setSubCategoryData] = useState([]);
  // get Categories List
  const getCategoyList = () => {

    getCategories().then(res => {
      if (res) {
        const filterCategory = res?.filter((i) => i?.status !== "0")
        setCategoryData(filterCategory)
        setSelectedCategoryId(filterCategory?.[0])
        setActiveIndex(0)
      }
    }).catch(err => {

    });
    // const option = {
    //   method: "GET",
    //   headers: {
    //     "access-control-allow-origin": "*",
    //     "content-type": "application/json",
    //   },
    //   url: `${Apiurl()}/categories`,
    // };
    // axios(option)
    //   .then((e) => {
    //     const filterCategory = e?.data?.data?.filter((i) => i?.status !== "0")
    //     setCategoryData(filterCategory)
    //     setSelectedCategoryId(filterCategory?.[0])
    //     setActiveIndex(0)
    //   })
    //   .catch((error) => {
    //   });
  };
  useEffect(() => {
    getCategoyList();

  }, [])
  // get sub category

  const getSubCategory = () => {
    const formData = new FormData();
    formData.append("category_id", selectedCategoryId?.id)
    //console.log("test",selectedCategoryId?.id )
    getSubCategories(formData).then(res => {
      if (res) {
        setSubCategoryData(res)
      }
    }).catch(err => {

    });
    // try {
    //   const formData = new FormData();
    //   formData.append("category_id", selectedCategoryId?.id)
    //   const option = {
    //     method: "POST",
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //       'access-control-allow-origin': '*',
    //     },
    //     url: `${Apiurl()}/get-subcategories-by-category-id `,
    //     data: formData
    //   };
    //   const response = await axios(option)
    //   setSubCategoryData(response?.data?.data)
    // } catch (error) {
    //   console.error(error);
    // }
  };
  useEffect(() => {
    if (selectedCategoryId) {
      setSubCategoryData([])
      getSubCategory();
    }
  }, [selectedCategoryId])
  // Sub category click function
  const handleSubCatClick = (item) => {
    if (getUserInfo) {
      navigate("/category");
    } else {
      SetLocalStorageStringify("subCategoryClick", item)
      navigate("/categoryInfo")
    }
  }
  const [activeIndex, setActiveIndex] = useState(null);
  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  return (
    <section className="hcat-cont">
      <div className="container px-5">
        <div className="text-center py-5 hcat-main">
          <p className='hex-p'>{language ? "श्रेणियाँ" : "Categories"}</p>
          <div className='hex-explore'>{language ? "उत्तराखंड में ट्रैवल ट्रेड श्रेणियों का अन्वेषण करें" : "Explore Travel Trade Categories in Uttarakhand"}</div>
        </div>
        <div className="hcat-second pb-5">
          <div className="hcat-left p-4">
            {/* <ul className='hcat-ul'> */}
            {categoryData?.map((cat, index) => {
              return (
                <div key={index + 1} className='hcat-new-btn-cont' onClick={() => setSelectedCategoryId(cat)}>
                  {cat?.id === selectedCategoryId?.id ?
                    <BtnGreen /> : <BtnGray />}
                  <span className={cat?.id === selectedCategoryId?.id ? 'hcat-inner-gg' : 'hcat-inner-bb'}>
                    <img src={`${Imageurl()}/${cat?.category_image}`} alt="" /> <span>{language ? cat?.category_name_hi : cat?.category_name}</span>
                  </span>
                </div>
                // <>
                //   <li onClick={() => setSelectedCategoryId(cat)}>
                //     <button className={cat?.id === selectedCategoryId?.id ? 'bg_image_green_home' : 'bg_image_white_home'}   > <img src={`${Imageurl()}/${cat?.category_image}`} alt="" /> <span>{language ? cat?.category_name_hi : cat?.category_name}</span></button>
                //   </li>
                // </>
              )
            })}
            {/* </ul> */}
          </div>
          <div className="hcat-right py-5">
            <div className="hcat-col-div">
              {subCategoryData?.map((sub) => {
                return (
                  <div className="hcat-col " onClick={() => handleSubCatClick(sub)}>
                    <div className='hcat-col-text'>{language ? sub?.category_name_hi : sub?.category_name}</div>
                    <span className='hcat-span'> <span className='hcat-inner-span'><img src={`${Imageurl()}/${sub?.category_image}`} alt="" /></span></span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="hcat-second-res pb-5">
          {
            categoryData.map((cat, index) => {
              const active = index === activeIndex ? 'active' : '';
              return (
                <div key={cat.title} className='hcat-res-bg'>
                  <div
                    className={`hcat-title ${active}`}
                    onClick={() => { onTitleClick(index); setSelectedCategoryId(cat) }}
                  >
                    <span><img src={`${Imageurl()}/${cat?.category_image}`} alt="" /> <span>{language ? cat.category_name_hi : cat.category_name}</span></span>
                    {active ? <BsChevronUp /> : <BsChevronDown />}
                  </div>
                  <div className={`hcat-content ${active}`}>
                    <div className="hcat-col-div">
                      {subCategoryData?.map((sub) => {
                        return (
                          <div className="hcat-col " onClick={() => handleSubCatClick(sub)}>
                            <div className='hcat-col-text'>    {language ? sub?.category_name_hi : sub?.category_name}

                              <span className='hcat-inner-span'><img src={`${Imageurl()}/${sub?.category_image}`} alt="" /></span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}
export default HomeCategory