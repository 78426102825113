import apiInstance from "./config";

export const getCategories = async () => {
    return await apiInstance.get('/categories')
        .then(res => res.data)
        .catch(err => err);
}

export const getSubCategories = async (payload) => {
    return await apiInstance.post('/get-subcategories-by-category-id', payload)
        .then(res => res.data)
        .catch(err => err);
}

export const getHomePageContent = async () => {
    return await apiInstance.get('/home-page')
        .then(res => res.data)
        .catch(err => err);
}

export const clearCache = async () => {
    return await apiInstance.get('/clear-cache')
        .then(res => res)
        .catch(err => err);
}

export const getGuideLines = async (payload) => {
    return await apiInstance.post('/category/guideline', payload)
        .then(res => res.data)
        .catch(err => err);
}

export const getDetails = async (payload) => {
    return await apiInstance.post('/categories/fees/get-details', payload)
        .then(res => res.data)
        .catch(err => err);
}