/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { DeleteIcon, EditIcon } from '../AdminIcon'
import { Modal, Table } from 'react-bootstrap'
import { Apiurl, GetLocalStorage, Imageurl } from '../../../Utils/Utils'
import axios from 'axios'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useNavigate } from 'react-router-dom'
import { HiPlus } from 'react-icons/hi'
import { deleteCategoryData, getCategoryListData } from '../../../services/api/admin'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'

const Category = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate()
    const token = GetLocalStorage("userInfo")
    const [categoryList, setCategoryList] = useState([])
    const [deleteData, setDeleteData] = useState()

    //category list
    const getCategoryList = async () => {
        try {
            const categoryList = await getCategoryListData()
            const data = categoryList?.data?.filter(i => i?.status !== "0")
            setCategoryList(data)
        } catch (err) {
            console.log("error on categoryList: ", err)
            navigate("/")
        }
    };

    useEffect(() => {
        getCategoryList()
    }, [])

    // delete Category
    const deleteCategory = async (obj) => {
        try {
            const formData = new FormData();
            console.log(obj.id)
            formData.append("category_id", obj.id)
            await deleteCategoryData(formData)
            getCategoryList()
            // Close the modal after deletion
            setDeleteData("");
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
                    <div>
                        <HomeIcon />Home  /  <span>Category</span>
                    </div>
                    <button className='adm-tab-btn' onClick={() => navigate("/admin/category/add")}><span><HiPlus />
                    </span> Add Category</button>
                </div>
                <div className="aod-inner pt-0">
                    <div className="aod-bottom">
                        <div className="adm-tabs-outer">
                            <button className={"active-adm-tab btn-first"}>Category List </button>
                        </div>
                        <div className="aod-head"></div>
                        <div className="ad-reg-table">
                            <Table size="sm" className='ad-cat-table mt-4'>
                                <thead>
                                    <tr>
                                        <th className='ad-sno'>S No</th>
                                        <th className='ad-sno'> Image</th>
                                        <th className='ad-long'> English Name</th>
                                        <th className='ad-long'> Hindi Name</th>
                                        <th className='ad-long'> Description</th>
                                        <th className='ad-long'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        categoryList?.map((item, index) => (
                                            <tr key={index} className={index % 2 != 0 ? "adm-even" : ""}>
                                                <td>{index + 1}</td>
                                                <td>{item?.category_image ? <img className='ad-cat-td-img' src={`${Imageurl()}/${item?.category_image}`} alt="" /> : "--"}</td>
                                                <td>{item?.category_name ? item?.category_name : "--"}</td>
                                                <td>{item?.category_name_hi ? item?.category_name_hi : "--"}</td>
                                                <td>{item?.category_description ? item?.category_description : "--"}</td>
                                                <td><span className='ad-cat-edit' onClick={() => { navigate("/admin/category/update", { state: item }) }}><EditIcon /></span><span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you really want to delete this Category ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div className="suf-btn p-2">
                        <button onClick={() => { deleteCategory(deleteData) }}>Yes</button>
                        <button className='suf-can-butt' onClick={() => setDeleteData("")}>No</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </AdminDashboard>
    )
}

export default Category
