import React from 'react';
import { CgClipboard } from 'react-icons/cg';
import { useLocation, useNavigate } from 'react-router-dom';

const SideMenu = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const navigate = useNavigate()



  return (
    <div className='side-menu adm-side-menu' id="LeftMenu">
      <ul className='ds-ul'>
        <li className='BorderB' onClick={() => navigate("/admin")}>
          <button className={pathName === "/admin" ? 'bg_image_green' : 'bg_image_white'}  > <span className='DashBig'>Dashboard</span></button>
        </li>
        <li onClick={() => navigate("/admin/login")}>
          <button className={pathName.includes("/admin/login") ? 'bg_image_green' : 'bg_image_white'}  > <CgClipboard /> <span>Login</span></button>
        </li>
        <li onClick={() => navigate("/admin/reset-password")}>
          <button className={pathName.includes("/admin/reset-password") ? 'bg_image_green' : 'bg_image_white'}  > <CgClipboard /> <span>Reset Password</span></button>
        </li>
        <li onClick={() => navigate("/admin/forgot-password")}>
          <button className={pathName.includes("/admin/forgot-password") ? 'bg_image_green' : 'bg_image_white'}  > <CgClipboard /> <span>Forgot Password</span></button>
        </li>
        <li onClick={() => navigate("/admin/registration")}>
          <button className={pathName.includes("/admin/registration") ? 'bg_image_green' : 'bg_image_white'}  > <CgClipboard /> <span>Registration</span></button>
        </li>
        <li onClick={() => navigate("/admin/category")}>
          <button className={pathName.includes("/admin/category") ? 'bg_image_green' : 'bg_image_white'}  > <CgClipboard /> <span>Category</span></button>
        </li>
        <li onClick={() => navigate("/admin/sub-category")}>
          <button className={pathName.includes("/admin/sub-category") ? 'bg_image_green' : 'bg_image_white'}  > <CgClipboard /> <span>Sub Category</span></button>
        </li>
        <li onClick={() => navigate("/admin/application-tabs")}>
          <button className={pathName.includes("/admin/application-tabs") ? 'bg_image_green' : 'bg_image_white'}  > <CgClipboard /> <span>Application Tabs</span></button>
        </li>
        <li onClick={() => navigate("/admin/application-sections")}>
          <button className={pathName.includes("/admin/application-sections") ? 'bg_image_green' : 'bg_image_white'}  > <CgClipboard /> <span>Application Sections</span></button>
        </li>
        <li onClick={() => navigate("/admin/application-form")}>
          <button className={pathName.includes("/admin/application-form") ? 'bg_image_green' : 'bg_image_white'}  > <CgClipboard /> <span>Application Form</span></button>
        </li>
        <li onClick={() => navigate("/admin/guidelines")}>
          <button className={pathName.includes("/admin/guidelines") ? 'bg_image_green' : 'bg_image_white'}  > <CgClipboard /> <span>Guidelines</span></button>
        </li>
      </ul>
    </div>
  );
};

export default SideMenu;
