import React, { useEffect, useState } from 'react'
import ForgotPasswordForm from '../../../components/Auth/ForgotPassword/ForgotPasswordForm'
import Banner from '../../../components/Banner/Banner'
import MainLayout from '../../../components/MainLayout/MainLayout'
import { useLanguage } from '../../../components/LanguageProvider/LanguageProvider '
import { forgotPassword } from '../../../services/api/auth'

const ForgotPassword = () => {
  const { language, setLanguage } = useLanguage();

  const [forgetPasswordFormData, setForgetPasswordFormData] = useState([]);
  const [forgetPasswordAllData, setForgetPasswordAllData] = useState();
  
  //  Get forget forms fields
  const GetForgetFormField = () => {
   
    forgotPassword().then(res => {
      if (res) {
        const filterOnlyRequiredFields = res?.data?.input_fields?.filter((i) => i?.required !== "0")
        const updatedData = filterOnlyRequiredFields?.map(item => ({
          ...item,
          value: '',
          error: ''  // Set the default value as needed
        }));
        setForgetPasswordFormData(updatedData)
        setForgetPasswordAllData(res?.data)
      }}).catch((error) => { 
    });
    
      /*const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      url: `${Apiurl()}/forgot-password-form`,
       };*/
    /*axios(option)
      .then((e) => {
        const filterOnlyRequiredFields = e?.data?.data?.input_fields?.filter((i) => i?.required !== "0")
        const updatedData = filterOnlyRequiredFields?.map(item => ({
          ...item,
          value: '',
          error: ''  // Set the default value as needed
        }));
        setForgetPasswordFormData(updatedData)
        setForgetPasswordAllData(e?.data?.data)



      })
      .catch((error) => {

      });*/
  };
  
  useEffect(() => {

    window.scrollTo(0, 0);

    GetForgetFormField()
  }, [])
  
  return (
    <>
      <MainLayout>
        <Banner first={language ? "होम" : "Home"} second={language ? "पासवर्ड भूल गए पृष्ठ" : "Forgot Password Page"} head={language ? "पासवर्ड भूल गए" : "Forgot Password"} />
        <ForgotPasswordForm forgetPasswordFormData={forgetPasswordFormData} setForgetPasswordFormData={setForgetPasswordFormData} forgetPasswordAllData={forgetPasswordAllData} />
      </MainLayout>
    </>
  )
}

export default ForgotPassword
