import React, { useEffect } from 'react'
import "./DashboardHeader.css"
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { DashboardBellIcon, DashboardToogleIcon } from '../DashboardIcons'
import { ClearLocalStorage } from '../../../Utils/Utils'
import { useLanguage } from '../../LanguageProvider/LanguageProvider '

const DashboardHeader = ({ sidebarCollapse, setSideBarCollapse, userDetail }) => {
    const { language, setLanguage } = useLanguage();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const navigate = useNavigate();
    const handleLogout = (e) => {
        if (e === "image") {
            navigate("/login")
            ClearLocalStorage();
        } else {
            ClearLocalStorage();
            navigate("/")
        }
    }
    return (
        <div>
            <div className='dh-cont fixed-top' >
                <Navbar className="bg-body hd-main">
                    <div className='m-0 w-100 d-flex main-dash-head'>
                        <div className="header-side-sec d-flex justify-content-between align-items-center ">
                            <div onClick={() => handleLogout("image")} className="navbar-brand " ><img className="dh-logo" src={'https://utdbstg.dilonline.in/assets/images/admin/website_logo_1718344813.png'} /></div>
                            <div onClick={() => setSideBarCollapse(!sidebarCollapse)}>

                                <DashboardToogleIcon />
                            </div>
                        </div>
                        <div className="col-md-9 d-flex align-items-center justify-content-end gap-3 main-dash-second-col">
                            <div className="hdt-language-dash d-flex mx-3">
                                <label className="form-check-label mx-2 " for="english">
                                    English
                                </label>
                                <div className="hdt-toggle-block-dash form-check  form-switch">
                                    <input className="form-check-input lightgreen flexSwitchCheckChecked" checked={language} type="checkbox" role="switch" id="changeLang" value="in" onChange={() => { setLanguage(!language) }} />
                                </div>
                                <label className="form-check-label mx-1 " for="hindi">
                                    हिंदी
                                </label>
                            </div>
                            <DashboardBellIcon />
                            <NavDropdown title={`Hi ${userDetail?.name}`} id="basic-nav-dropdown" className='dh-nav-dropdown'>
                                <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                            </NavDropdown>

                        </div>
                        <div className="col-md-1"></div>



                    </div>
                </Navbar>
            </div>

        </div>
    )
}

export default DashboardHeader
