import apiInstance from "./config";

export const getCategoryListData = async () => {
    return await apiInstance.get('/admin/categories')
        .then(res => res)
        .catch(err => err);
}

export const deleteCategoryData = async (payload) => {
    return await apiInstance.post('/admin/categories/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const addCategoryData = async (payload) => {
    return await apiInstance.post('/admin/categories/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateSubCategoryData = async (payload) => {
    return await apiInstance.post('/admin/categories/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const getFontFamilyData = async () => {
    return await apiInstance.get('/get-fontfamilies')
        .then(res => res)
        .catch(err => err);
}

export const getSubCategoryListData = async () => {
    return await apiInstance.get('/admin/sub-categories')
        .then(res => res)
        .catch(err => err);
}

export const getRegistrationFieldsListData = async () => {
    return await apiInstance.get('/admin/registration-form/fields')
        .then(res => res)
        .catch(err => err);
}

export const addRegistrationFieldData = async (payload) => {
    return await apiInstance.post('/admin/registration-form/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const deleteRegistrationFieldData = async (payload) => {
    return await apiInstance.post('/admin/registration-form/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const getValidationsListData = async () => {
    return await apiInstance.get('/admin/validation-rules')
        .then(res => res)
        .catch(err => err);
}

export const updateRegistrationFieldData = async (payload) => {
    return await apiInstance.post('/admin/registration-form/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const addSubCategoryTabData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const getFormTabListData = async () => {
    return await apiInstance.get('/admin/categories/tabs')
        .then(res => res)
        .catch(err => err);
}

export const deleteApplicationTabData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateSubCategoryTabData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateSubCategoryTabSectionData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/sections/update', payload)
        .then(res => res)
        .catch(err => err);
}


export const handleEnableDisableCategoryData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/enable-disable', payload)
        .then(res => res)
        .catch(err => err);
}

export const addSubCategorySectionTabData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/sections/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const getFormTabSubCategoryListData = async (payload) => {
    return await apiInstance.post('admin/categories/tabs/list', payload)
        .then(res => res)
        .catch(err => err);
}

export const getSectionsData = async () => {
    return await apiInstance.get('/admin/categories/tabs/sections')
        .then(res => res)
        .catch(err => err);
}

export const deleteApplicationSectionsTabData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/sections/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const handleEnableSectionsDisableData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/sections/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const addFormFieldData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/fields/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const getSectionListDataByTabId = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/sections/by-tab_id', payload)
        .then(res => res)
        .catch(err => err);
}

export const getFormFieldsListData = async () => {
    return await apiInstance.get('/admin/categories/tabs/fields')
        .then(res => res)
        .catch(err => err);
}

export const deleteFormFieldData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/fields/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateFormFieldData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/fields/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const handleEnableDisableFormFieldsData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/fields/enable-disable', payload)
        .then(res => res)
        .catch(err => err);
}

export const getUsersListData = async () => {
    return await apiInstance.get('/admin/users')
        .then(res => res)
        .catch(err => err);
}

export const getLoginFieldsData = async () => {
    return await apiInstance.get('/login-form')
        .then(res => res)
        .catch(err => err);
}

export const updateLoginFieldData = async (payload) => {
    return await apiInstance.post('/admin/login-form/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const getResetPasswordFieldData = async () => {
    return await apiInstance.get('/admin/reset-password-form')
        .then(res => res)
        .catch(err => err);
}


export const updateResetPasswordFieldData = async (payload) => {
    return await apiInstance.post('/admin/reset-password-form/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const getForgotPasswordFieldsData = async () => {
    return await apiInstance.get('/admin/forgot-password-form')
        .then(res => res)
        .catch(err => err);
}

export const updateForgotPasswordFieldsData = async (payload) => {
    return await apiInstance.post('/admin/forgot-password-form/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const getGuidelinesFieldsData = async () => {
    return await apiInstance.get('admin/guidelines')
        .then(res => res)
        .catch(err => err);
}

export const addGuidelinesFieldsData = async (payload) => {
    return await apiInstance.post('/admin/guidelines/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateGuidelinesFieldsData = async (payload) => {
    return await apiInstance.post('/admin/guidelines/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const deleteGuidelinesFieldsData = async (payload) => {
    return await apiInstance.post('/admin/guidelines/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const activeInactiveTabs = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}