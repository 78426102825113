import React from 'react'
import "./Footer.css"
import { BiLogoFacebook } from "react-icons/bi";
import { BsTwitterX } from "react-icons/bs";
import { AiOutlineYoutube } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import { RiLinkedinLine } from "react-icons/ri";
import octicongraph from "../../assets/images/octicon-graph.png"
import trainingprogram from "../../assets/images/training-program.png"
import photography from "../../assets/images/photography.png"
import transferpolicy from "../../assets/images/transfer-policy.png"
import register from "../../assets/images/register.png"
import login from "../../assets/images/login.png"
import { useLocation, useNavigate } from 'react-router-dom';
import { useLanguage } from '../LanguageProvider/LanguageProvider ';
const Footer = ({ homePageContent, getUserInfo }) => {
    const { language, setLanguage } = useLanguage()
    const navigate = useNavigate()
    const location = useLocation();
    const activeHome = location.pathname == "/" || location.pathname == "/login" || location.pathname == "/signup" || location.pathname == "/reset-password" || location.pathname == "/forgot-password"
    return (
        <div className='ft-main m-0' style={{ display: getUserInfo && !activeHome ? "none" : "block" }}>
            <div className="container">
                <div className='ft-upper row'>
                    <div className='upper-box1 col-md-5'>
                        <p className='box2-pera-heading'>{language ? "यूटीडीबी के बारे में" : "About UTDB"}</p>
                        <p className='box2-pera-line'></p>
                        <p className='box1-pera'>{language ? "लोरेम इप्सम 1500 के दशक से ही उद्योग का मानक डमी टेक्स्ट रहा है, जब एक अज्ञात प्रिंटर ने टाइप की गैली ली और उसे खंगाला।" : "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled"} </p>
                        <div className='box1-icons'>
                            <div className='b1-icons-inner'>{homePageContent?.homePageContent?.footer_link?.name}< BiLogoFacebook /></div>
                            <div className='b1-icons-inner'>< BsTwitterX /></div>
                            <div className='b1-icons-inner'>< AiOutlineYoutube /></div>
                            <div className='b1-icons-inner'>< AiOutlineInstagram /></div>
                            <div className='b1-icons-inner'>< RiLinkedinLine /></div>
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className='upper-box2 col-md-3'>
                        <p className='box2-pera-heading'>{language ? "ट्रैवल ट्रेड" : "Travel Trade"}</p>
                        <p className='box2-pera-line'></p>
                        <div className='box2-pera-content'>
                            <div className='ft-box2-lines'> <img src={octicongraph} alt="" /> <p> {language ? "उत्तराखंड पर्यटन में निवेश करें" : "Invest in Uttarakhand Tourism"}</p></div>
                            <div className='ft-box2-lines'> <img src={trainingprogram} alt="" /> <p> {language ? "सीबीएसपी प्रशिक्षण कार्यक्रम" : "CBSP Training Program"}</p></div>
                            <div className='ft-box2-lines'> <img src={photography} alt="" /> <p> {language ? "फोटोग्राफी प्रतियोगिता" : "Photography Contest"}</p></div>
                            <div className='ft-box2-lines'> <img src={transferpolicy} alt="" /> <p> {language ? "स्थानांतरण नीति 2024" : "Transfer Policy 2024"}</p></div>
                        </div>
                        {/* <div className='box2-pera-content'>
                            {homePageContent?.homePageContent?.footer_link?.map((item, index) => {
                                return (
                                    <>
                                        <a key={index} href={item.url} className='box2-pera-content-links'>{item.name}</a>
                                    </>
                                )
                            }
                            )}
                        </div> */}
                    </div>
                    <div className='upper-box3 col-md-2'>
                        <p className='box3-pera-heading'>{language ? "महत्वपूर्ण लिंक" : "Important Links"}</p>
                        <p className='box3-pera-line'></p>
                        <div className='box3-pera-content'>
                            <div className='ft-box2-lines' onClick={() => navigate("/signup")}> <img src={login} alt="" />  <p> {language ? "रेजिस्ट्रेशन" : "Registration"}</p></div>
                            <div className='ft-box2-lines' onClick={() => navigate("/login")}> <img src={register} alt="" /><p> {language ? "लॉग इन " : "Login"}</p></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ft-lower">
                <div className='container'>
                    <div className='ft-lower-box1'>
                        <p>{language ? "सर्वोत्तम अनुभव के लिए, कृपया साइट को 1024 x 768 रिज़ॉल्यूशन में देखें। इसे क्रोम (v84+), सफारी (v14+), और फ़ायरफ़ॉक्स (v90+) जैसे आधुनिक ब्राउज़रों के लिए अनुकूलित किया गया है।" : "For the best experience, please view the site in 1024 x 768 resolution. It's optimized for modern browsers like Chrome (v84+), Safari (v14+), and Firefox (v90+)."} </p>
                        <p>{language ? "इस वेबसाइट की सामग्री उत्तराखंड पर्यटन विकास बोर्ड (यूटीडीबी), पर्यटन विभाग, उत्तराखंड सरकार द्वारा प्रकाशित और प्रबंधित की जाती है।" : "Contents of this website is published and managed by Uttarakhand Tourism Development Board (UTDB), Department of Tourism, Government of Uttarakhand."}</p>
                    </div>
                    <div className='ft-lower-box2'>
                        <div className='ft-lower-box2-p1'><p>{language ? "कॉपीराइट © 2024 उत्तराखंड पर्यटन विकास बोर्ड। - सर्वाधिकार सुरक्षित।" : "Copyright © 2024 Uttarakhand Tourism Development Board. - All rights reserved."}</p></div>
                        <div className='ft-lower-box2-p2'>
                            <p>{language ? "नियम एवं शर्तें" : "Terms & Conditions"}</p>
                            <p>{language ? "अस्वीकरण" : "Disclaimer"}</p>
                            <p>{language ? "गोपनीयता नीति" : "Privacy Policy"}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer