/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { DeleteIcon, EditIcon } from '../AdminIcon'
import { Modal, Table } from 'react-bootstrap'
import { Apiurl, GetLocalStorage, Imageurl } from '../../../Utils/Utils'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useNavigate } from 'react-router-dom'
import { HiPlus } from 'react-icons/hi'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { deleteCategoryData, getCategoryListData, getSubCategoryListData } from '../../../services/api/admin';

const SubCategory = () => {
  const token = GetLocalStorage("userInfo")
  const navigate = useNavigate()
  const [categoryList, setCategoryList] = useState([])
  const [subCategoryList, setSubCategoryList] = useState([])
  const [deleteData, setDeleteData] = useState()

  //category list
  const getCategoryList = async () => {
    try {
      const categoryList = await getCategoryListData()
      const data = categoryList?.data?.filter(i => i?.status === "1")?.map(item => ({
        value: item?.id,
        label: item?.category_name
      }))
      setCategoryList(data)
    } catch (err) {
      console.log("error on sub Ctaegory list: ", err)
      navigate("/")
    }
  };

  // sub category list
  const getSubCategoryList = async () => {
    try {
      const subCategoryList = await getSubCategoryListData()
      const data = subCategoryList?.data?.filter(i => i?.status === "1")
      setSubCategoryList(data)
    } catch (err) {
      console.log("Error on subCategorylist: ", err)
    }
  };
  //get font families
  useEffect(() => {
    getCategoryList()
    getSubCategoryList()
  }, [])

  // delete sub-Category
  const deleteSubCategory = async (item) => {
    try {
      const formData = new FormData();
      formData.append("parent_category_id", item?.parent_category_id)
      formData.append("category_id", item?.id)
      await deleteCategoryData(formData)
      getSubCategoryList()
      setDeleteData("")
    } catch (error) {
      console.error(error);
    }
  }


  return (
    <AdminDashboard>
      <div className='aod-outer'>
        <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
          <div>
            <HomeIcon />Home  /  <span>Sub Category</span>
          </div>
          <button className='adm-tab-btn' onClick={() => navigate("/admin/sub-category/add")}><span><HiPlus />
          </span> Add Sub Category</button>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer">
              <button className={"active-adm-tab btn-first"}>Sub Category List </button>
            </div>
            <div className="aod-head"></div>
            <div className="ad-reg-table">
              <Table size="sm" className='ad-cat-table mt-4'>
                <thead>
                  <tr>
                    <th className='ad-sno'>S No</th>
                    {/* <th className='ad-long'>Category Id</th> */}
                    <th className='ad-long'>Category Name</th>
                    {/* <th className='ad-long'>Sub Category Id </th> */}
                    <th className='ad-long'>Image</th>
                    <th className='ad-long'>English Name</th>
                    <th className='ad-long'>Hindi Name</th>
                    <th className='ad-long'>Description</th>
                    <th className='ad-long'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    subCategoryList?.map((item, index) => (
                      <tr key={index} className={index % 2 != 0 ? "adm-even" : ""}>
                        <td>{index + 1}</td>
                        {/* <td>{item?.parent_category_id} </td> */}
                        <td>{categoryList?.find(i => i.value == item?.parent_category_id)?.label}</td>
                        {/* <td>{item?.id}</td> */}
                        <td>{item?.category_image ? <img className='ad-cat-td-img sub-cat-img' src={`${Imageurl()}/${item?.category_image}`} alt="" /> : "--"}</td>
                        <td>{item?.category_name ? item?.category_name : "--"}</td>
                        <td>{item?.category_name_hi ? item?.category_name_hi : "--"}</td>
                        <td>{item?.category_description ? item?.category_description : "--"}</td>
                        <td><span className='ad-cat-edit' onClick={() => { navigate("/admin/sub-category/update", { state: item }) }}><EditIcon /></span><span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span></td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
        <Modal.Header closeButton>
          <Modal.Title>Do you really want to delete sub this Category ?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body></Modal.Body> */}
        <Modal.Footer>
          <div className="suf-btn p-2">
            <button onClick={() => { deleteSubCategory(deleteData) }}>Yes</button>
            <button className='suf-can-butt' onClick={() => setDeleteData("")}>No</button>
          </div>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  )
}

export default SubCategory
