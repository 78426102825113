import React, { useEffect, useState } from 'react'
import { Apiurl, GetLocalStorage, Imageurl, RemoveLocalStorage, SetLocalStorageStringify } from '../../Utils/Utils';
import homebg from "../../assets/images/home-cat-bg.png"
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeResidentialUnit } from '../Home/HomeIcons';
import { BsArrowRight, BsXLg } from "react-icons/bs";
import './DashboardCategory.css'
import { BackgorundIcon, DashboardIcon } from '../Application/ApplicationIcon';
import { useLanguage } from '../LanguageProvider/LanguageProvider ';
import { getGuideLines } from '../../services/api/user';
import { getSubCategories, getCategories } from '../../services/api/outer'
const DashCategory = () => {
    // token from local storage
    const { language } = useLanguage();
    const navigate = useNavigate();
    const getLoginSubCabData = GetLocalStorage("LoginSubCatData");
    const getNotSelectData = GetLocalStorage("catNotSelect");

    const location = useLocation()
    const getUserInfo = GetLocalStorage("userInfo");
    const [registerMessage, setRegisterMessage] = useState(false)
    const [notSelectedMessage, setNotSelectedMessage] = useState(false)
    const [categoryData, setCategoryData] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState();
    const [subCategoryData, setSubCategoryData] = useState([]);
    useEffect(() => {
        if (location.state == "register") {
            setRegisterMessage(true)
        }
    }, [])
    useEffect(() => {
        if (!getLoginSubCabData && getNotSelectData) {
            setNotSelectedMessage(true)
        }
    }, [])
    
    // get Categories List
    const GetCategoyList = () => {

        getCategories().then(res => {
            if (res) {
                setCategoryData(res)
                setSelectedCategoryId(res?.[0])
            }
        }).catch(err => {

        });

        // const option = {
        //     method: "GET",
        //     headers: {
        //         "access-control-allow-origin": "*",
        //         "content-type": "application/json",
        //     },
        //     url: `${Apiurl()}/categories`,
        // };
        // axios(option)
        //     .then((e) => {
        //         setCategoryData(e?.data?.data)
        //         setSelectedCategoryId(e?.data?.data?.[0])
        //     })
        //     .catch((error) => {
        //     });
    };
    useEffect(() => {
        GetCategoyList();
    }, [])
    
    
    // get sub category
    const getSubCategory = () => {

        const formData = new FormData();
        formData.append("category_id", selectedCategoryId?.id)
        //console.log("test",selectedCategoryId?.id )
        getSubCategories(formData).then(res => {
            if (res) {
                setSubCategoryData(res)
            }
        }).catch(err => {

        });
        // try {
        //     const formData = new FormData();
        //     formData.append("category_id", selectedCategoryId?.id)
        //     const option = {
        //         method: "POST",
        //         headers: {
        //             'Content-Type': 'multipart/form-data',
        //             'access-control-allow-origin': '*',
        //         },
        //         url: `${Apiurl()}/get-subcategories-by-category-id `,
        //         data: formData
        //     };
        //     const response = await axios(option)
        //     setSubCategoryData(response?.data?.data)
        // } catch (error) {
        //     console.error(error);
        // }
    };
    useEffect(() => {
        if (selectedCategoryId) {
            getSubCategory();
        }
    }, [selectedCategoryId])
    

 

    
    // Sub category click function
    const handleSubCatClick = (item) => {


        if (getUserInfo) {
            navigate("/guidelines");
            SetLocalStorageStringify("LoginSubCatData", item)
        } else {
            navigate("/login")
        }
    }
    return (
        <>
            {
                registerMessage && <div className='dc-reg-msg'> Registered Successfully. <span onClick={() => { setRegisterMessage(false); }}><BsXLg /></span></div>
            }
            {
                notSelectedMessage && <div className='dc-reg-msg'> Please select category & subcategory first. <span onClick={() => { setNotSelectedMessage(false); RemoveLocalStorage("catNotSelect") }}><BsXLg /></span></div>
            }
            <div className="gc-outline">
                <div className="ao-top">
                    <div className='col-md-2'>
                        <DashboardIcon />
                    </div>
                    <div className="ao-toptext col-md-8">
                        <p>{language ? "ट्रैवल ट्रेड डैशबोर्ड में आपका स्वागत है!" : "Welcome to Travel Trade Dashboard!"} </p>
                        <p>{language ? "जहां रोमांच शुरू होता है!" : " Where Adventures Begin!"}</p>
                    </div>
                    <div className='col-md-2 wel-date-sec'>
                        <p>{language ? "तारीख:15 जून 2024" : "Date: 15 June 2024"}</p>
                    </div>
                </div>
                <div className="hcat-second pb-5">
                    <img src={homebg} alt="homebg" className='hcat-bg-end' />
                    <div className="hcat-left p-3">
                        <ul className='hcat-ul'>
                            {/* category mapping */}
                            {categoryData?.map((cat) => {
                                return (
                                    <>
                                        <li onClick={() => setSelectedCategoryId(cat)}>
                                            {/* <button className='hcat-green'><HomeResidentialUnit />Residential Unit <span></span></button> */}
                                            <button className={cat?.id === selectedCategoryId?.id ? 'bg_image_green_home' : 'bg_image_white_home'}   > <img src={`${Imageurl()}/${cat?.category_image}`} alt="" /> <span>{language ? cat?.category_name_hi : cat?.category_name}</span></button>
                                        </li>
                                    </>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="hcat-right dash-cat-right py-5">
                        <div className="hcat-col-div dash-cat-col-div">
                            {subCategoryData?.map((sub) => {
                                return (
                                    <div className="dash-cat-main " onClick={() => handleSubCatClick(sub)}>
                                        <BsArrowRight className='dash-cat-icon' />
                                        <div className='dash-cat-inner'>
                                            <span className='dash-cat-name'> {language ? sub?.category_name_hi : sub?.category_name}</span>
                                            <span className='dash-cat-no'>0</span>
                                        </div>
                                        <div className='das-cat-sec-div'>
                                            <img src={`${Imageurl()}/${sub?.category_image}`} alt="" className='subCat-img-das' />
                                            <div className='guide-main-div'>
                                                {/* <BackgorundIcon /> */}
                                                <div className='dc-guide-par'>
                                                    <p>
                                                        {language ? "दिशानिर्देश पढ़ें" : "Read Guidelines"}  <span></span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DashCategory