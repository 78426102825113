import React, { useEffect, useState } from 'react'
import { AccomodationIcon, AdditionalIcon, EmployeeIcon, OrganizationIcon, PaymentIcon, UploadIcon, UploadIconPhoto } from './ApplicationIcon'
import Dashboard from '../../pages/Dashboard/Dashboard';
import { Apiurl, GetLocalStorage } from '../../Utils/Utils';
import axios from 'axios';
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import { BsXLg } from "react-icons/bs";


const OrganizationDetails = ({ tabsData, tabActiveData, setTabActiveData, inputFormData, setInputFormData, getLoginSubCabData }) => {
    const navigate = useNavigate();
    const location=useLocation();
    const [registerMessage, setRegisterMessage] = useState(false)
    useEffect(() => {
        if (location.state == "register") {
            setRegisterMessage(true)
        }
    },  
    [])
    const token = GetLocalStorage("userInfo")
    // first section states
    const [districtsBySection, setDistrictsBySection] = useState({});
    const [valueStore, setValueStore] = useState({});
    const [citiesBySection, setCitiesBySection] = useState({});
    const [dummyDAta, setDummyData] = useState();
    const [imageMessage, setImageMessage] = useState();
    const [clickDropdown, setClickDropdown] = useState({
        state_id: false,
        district_id: false,
        city_id: false
    })
    const [imageData, setImageData] = useState('');
    const handleImageUpload = (e, fieldId, sectionId) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                // Update the valueStore state
                setValueStore(prevValueStore => ({
                    ...prevValueStore,
                    [`${imageData}-${fieldId}`]: base64String
                }));
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${imageData}-${fieldId}`]: ""
                }));
                setImageMessage(
                    { [`${imageData}-${fieldId}`]: "Image Uploaded" }
                );
                let updatedSections = inputFormData?.sections?.map(section => {
                    // Check if this is the section we're updating
                    if (section.id === imageData) {
                        let updatedFields = section.category_form_feilds.map(field => {
                            if (field.id === fieldId) {
                                return { ...field, value: base64String }; // Assign the Base64 string to value
                            }
                            return field;
                        });
                        return { ...section, category_form_feilds: updatedFields };
                    }
                    return section;
                });
                // Update the inputFormData state with the new sections
                setInputFormData(prevFormData => ({
                    ...prevFormData,
                    sections: updatedSections
                }));
                let update = dummyDAta?.map(section => {
                    // Check if this is the section we're updating
                    if (section.id === sectionId) {
                        let updatedFields = section.category_form_feilds.map(field => {
                            if (field.id === fieldId) {
                                return { ...field, value: base64String };
                            }

                            return field;
                        });
                        return { ...section, category_form_feilds: updatedFields };
                    }
                    return section;
                });
                setDummyData(update);
            };
            reader.readAsDataURL(file); // This should be called outside onloadend
        }
    };


    const [inputFieldErrorMessage, setInputFieldErrorMessage] = useState(
        {
        }
    )

    //  add input fields according to owner
    const [numPartners, setNumPartners] = useState(0);
    const handlePartnersChange = (e, fieldId, sectionId) => {
        const value = e.target.value;

        // If the value is an empty string, handle it separately
        if (value === "") {
            setNumPartners(0);

            // Update the inputFormData state
            let updatedSections = inputFormData?.sections.map(section => {
                let updatedFields = section.category_form_feilds.map(field => {
                    if (field.id === fieldId) {

                        setValueStore(prevValueStore => ({
                            ...prevValueStore,
                            [`${sectionId}-${fieldId}`]: ""
                        }));
                        return { ...field, value: "" };
                    }
                    return field;
                });
                return { ...section, category_form_feilds: updatedFields };
            });
            setInputFormData({ ...inputFormData, sections: updatedSections });
        } else {
            const numValue = parseInt(value, 10);

            // Check if the value is a number and within the range of 1 to 10
            if (!isNaN(numValue) && numValue >= 1 && numValue <= 10) {
                setNumPartners(numValue);

                // Update the inputFormData state
                let updatedSections = inputFormData?.sections.map(section => {
                    let updatedFields = section.category_form_feilds.map(field => {
                        if (field.id === fieldId) {
                            setValueStore(prevValueStore => ({
                                ...prevValueStore,
                                [`${sectionId}-${fieldId}`]: numValue
                            }));
                            return { ...field, value: numValue };
                        }
                        return field;
                    });
                    return { ...section, category_form_feilds: updatedFields };
                });
                setInputFormData({ ...inputFormData, sections: updatedSections });
            } else {
                // Set the number of partners to 0 if the input is not valid
                setNumPartners(0);
            }
        }
    };
    // add fields in same api repsonse data 
    useEffect(() => {
        if (numPartners) {
            const updatedSections = inputFormData?.sections.filter(section => section.section_key !== 'owners_partners');
            const ownerPartnerSection = inputFormData?.sections.find(section => section.section_key === 'owners_partners');

            const newOwnerPartnerSections = [];
            for (let i = 0; i < numPartners; i++) {
                const existingSection = inputFormData?.sections.find(section => section.id === i + 3);

                if (existingSection) {
                    // If the section already exists, keep its values
                    newOwnerPartnerSections.push(existingSection);
                } else {
                    // Create a new section with empty values
                    const emptyFields = ownerPartnerSection.category_form_feilds.map(field => ({
                        ...field,
                        value: ""
                    }));
                    newOwnerPartnerSections.push({ ...ownerPartnerSection, id: Number(i + 3), name_en: `Owner / Partner ${i + 1}`, category_form_feilds: emptyFields });
                }
            }

            setInputFormData({
                ...inputFormData,
                sections: [...updatedSections, ...newOwnerPartnerSections]
            });
            setDummyData([...newOwnerPartnerSections])
        }
    }, [numPartners]);

    // state, city, district


    const [statesData, setStatesData] = useState([]);
    // get state
    const GetStates = () => {
        const option = {
            method: "GET",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
            },
            url: `${Apiurl()}/states`,
        };
        axios(option)
            .then((e) => {
                setStatesData(e?.data?.data)
            })
            .catch((error) => {
            });
    };
    //  Get district
    const GetDistricts = (stateId, sectionId) => {
        const option = {
            method: "GET",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
            },
            url: `${Apiurl()}/districts`,
        };

        axios(option)
            .then((e) => {
                const data = e?.data?.data;
                const filteredDistricts = data.filter((i) => i?.state_id === stateId);
                filteredDistricts.push({ name: "Other", id: 999 });

                // Update districts for the specific section
                setDistrictsBySection(prevState => ({
                    ...prevState,
                    [sectionId]: filteredDistricts
                }));
            })
            .catch((error) => {
                console.error(error); // Add error handling logic if needed
            });
    };

    const GetCities = (districtId, sectionId) => {
        const option = {
            method: "GET",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
            },
            url: `${Apiurl()}/cities`,
        };

        axios(option)
            .then((e) => {
                const data = e?.data?.data;
                const filteredCities = data.filter((i) => i?.district_id === districtId);
                filteredCities.push({ name: "Other", id: 999 });

                // Update cities for the specific section
                setCitiesBySection(prevState => ({
                    ...prevState,
                    [sectionId]: filteredCities
                }));
            })
            .catch((error) => {
                console.error(error); // Add error handling logic if needed
            });
    };

    useEffect(() => {
        GetStates()
    }, [])
    //  state, district,city onchange
    const handleDropdownChangeFirst = (e, fieldName, fieldId, sectionId) => {
        const selectedValue = e.value;

        if (fieldName === "state_id") {
            setInputFieldErrorMessage((prevErrors) => ({
                ...prevErrors,
                [`${sectionId}-${fieldId}`]: ""
            }));
            setClickDropdown({ ...clickDropdown, state_id: false })

        }
        if (fieldName === "partner_state_id") {
            setInputFieldErrorMessage((prevErrors) => ({
                ...prevErrors,
                [`${sectionId}-${fieldId}`]: ""
            }));
            setClickDropdown({ ...clickDropdown, state_id: false })

        }
        if (fieldName === "district_id") {
            setClickDropdown({ ...clickDropdown, district_id: false })
            setInputFieldErrorMessage((prevErrors) => ({
                ...prevErrors,
                [`${sectionId}-${fieldId}`]: ""
            }));
        }
        if (fieldName === "partner_district_id") {
            setInputFieldErrorMessage((prevErrors) => ({
                ...prevErrors,
                [`${sectionId}-${fieldId}`]: ""
            }));
            setClickDropdown({ ...clickDropdown, state_id: false })

        }
        if (fieldName === "city_id") {
            setClickDropdown({ ...clickDropdown, city_id: false })
            setInputFieldErrorMessage((prevErrors) => ({
                ...prevErrors,
                [`${sectionId}-${fieldId}`]: ""
            }));
        }
        if (fieldName === "partner_city_id") {
            setInputFieldErrorMessage((prevErrors) => ({
                ...prevErrors,
                [`${sectionId}-${fieldId}`]: ""
            }));
            setClickDropdown({ ...clickDropdown, state_id: false })

        }

        // Update the inputFormData state
        let updatedSections = inputFormData?.sections?.map(section => {
            // Check if this is the section we're updating
            if (section.id === sectionId) {
                let updatedFields = section.category_form_feilds.map(field => {
                    if (field.input_field_name === fieldName) {
                        setValueStore(prevValueStore => ({
                            ...prevValueStore,
                            [`${sectionId}-${fieldId}`]: selectedValue
                        }));
                        return { ...field, value: selectedValue };
                    }
                    // Reset district and city fields when state changes
                    if (fieldName === "state_id" || fieldName === "partner_state_id") {
                        if (field.input_field_name === "district_id" || field.input_field_name === "partner_district_id" || field.input_field_name === "city_id" || field.input_field_name === "partner_city_id") {

                            return { ...field, value: null };
                        }
                    }
                    // Reset city field when district changes
                    if (fieldName === "district_id" || fieldName === "partner_district_id") {
                        if (field.input_field_name === "city_id" || field.input_field_name === "partner_city_id") {
                            return { ...field, value: null };
                        }
                    }
                    return field;
                });
                return { ...section, category_form_feilds: updatedFields };
            }
            return section;
        });
        setInputFormData({ ...inputFormData, sections: updatedSections });

        let update = dummyDAta?.map(section => {
            // Check if this is the section we're updating
            if (section.id === sectionId) {
                let updatedFields = section.category_form_feilds.map(field => {
                    if (field.input_field_name === fieldName) {

                        return { ...field, value: selectedValue };
                    }
                    // Reset district and city fields when state changes
                    if (fieldName === "state_id" || fieldName === "partner_state_id") {
                        if (field.input_field_name === "district_id" || field.input_field_name === "partner_district_id" || field.input_field_name === "city_id" || field.input_field_name === "partner_city_id") {
                            return { ...field, value: null };
                        }
                    }
                    // Reset city field when district changes
                    if (fieldName === "district_id" || fieldName === "partner_district_id") {
                        if (field.input_field_name === "city_id" || field.input_field_name === "partner_city_id") {
                            return { ...field, value: null };
                        }
                    }
                    return field;
                });
                return { ...section, category_form_feilds: updatedFields };
            }
            return section;
        });
        setDummyData(update)


        // Fetch districts if state changes
        if (fieldName === "state_id" || fieldName === "partner_state_id") {
            GetDistricts(selectedValue, sectionId);
        }
        // Fetch cities if district changes
        if (fieldName === "district_id" || fieldName === "partner_district_id") {
            GetCities(selectedValue, sectionId);
        }
    };


    //  handle input change
    const handleFieldChange = (e, fieldId, sectionId) => {

        let newValue = e.target.value;

        // Prevent space as the first character
        if (newValue.startsWith(' ')) {
            newValue = newValue.trimStart();
        }

        // Remove any special characters and numbers
        newValue = newValue.replace(/[^a-zA-Z ]/g, '');

        // Enforce max length of 40 characters
        if (newValue.length > 40) {
            newValue = newValue.substring(0, 40);
        }

        // Update the inputFormData state
        let updatedSections = inputFormData?.sections?.map(section => {
            if (section.id === sectionId) {
                let updatedFields = section.category_form_feilds.map(field => {
                    if (field.id === fieldId) {
                        setValueStore(prevValueStore => ({
                            ...prevValueStore,
                            [`${section?.id}-${field?.id}`]: newValue
                        }));

                        return { ...field, value: newValue };
                    }
                    return field;
                });
                return { ...section, category_form_feilds: updatedFields };
            }
            return section;
        });

        setInputFormData({ ...inputFormData, sections: updatedSections });
        // Update the inputFormData state
        let updated = dummyDAta?.map(section => {
            if (section.id === sectionId) {
                let updatedFields = section.category_form_feilds.map(field => {
                    if (field.id === fieldId) {
                        return { ...field, value: newValue };
                    }
                    return field;
                });
                return { ...section, category_form_feilds: updatedFields };
            }
            return section;
        });

        setDummyData(updated)
    };
    //  handle email change
    const handleEmailChange = (e, fieldId, sectionId) => {

        let newValue = e.target.value;

        // Prevent space as the first character
        if (newValue.startsWith(' ')) {
            newValue = newValue.trimStart();
        }




        // Update the inputFormData state
        let updatedSections = inputFormData?.sections?.map(section => {
            if (section.id === sectionId) {
                let updatedFields = section.category_form_feilds.map(field => {
                    if (field.id === fieldId) {
                        setValueStore(prevValueStore => ({
                            ...prevValueStore,
                            [`${section?.id}-${field?.id}`]: newValue
                        }));

                        return { ...field, value: newValue };
                    }
                    return field;
                });
                return { ...section, category_form_feilds: updatedFields };
            }
            return section;
        });

        setInputFormData({ ...inputFormData, sections: updatedSections });
        // Update the inputFormData state
        let updated = dummyDAta?.map(section => {
            if (section.id === sectionId) {
                let updatedFields = section.category_form_feilds.map(field => {
                    if (field.id === fieldId) {
                        return { ...field, value: newValue };
                    }
                    return field;
                });
                return { ...section, category_form_feilds: updatedFields };
            }
            return section;
        });

        setDummyData(updated)
    };
    //  handle house number change
    const handleHouseNumberChange = (e, fieldId, sectionId) => {

        let newValue = e.target.value;

        // Prevent space as the first character
        if (newValue.startsWith('')) {
            newValue = newValue.trimStart();
        }

        // Remove special characters and limit the length to 40
        newValue = newValue.replace(/[^a-zA-Z0-9 ]/g, '').slice(0, 40);

        // Update the inputFormData state
        let updatedSections = inputFormData?.sections?.map(section => {
            // Check if this is the section we're updating
            if (section.id === sectionId) {
                let updatedFields = section.category_form_feilds.map(field => {
                    if (field.id === fieldId) {
                        // Update the valueStore state
                        setValueStore(prevValueStore => ({
                            ...prevValueStore,
                            [`${section?.id}-${field?.id}`]: newValue
                        }));

                        return { ...field, value: newValue };
                    }
                    return field;
                });
                return { ...section, category_form_feilds: updatedFields };
            }
            return section;
        });

        setInputFormData({ ...inputFormData, sections: updatedSections });
        // Update the inputFormData state
        let updated = dummyDAta?.map(section => {
            // Check if this is the section we're updating
            if (section.id === sectionId) {
                let updatedFields = section.category_form_feilds.map(field => {
                    if (field.id === fieldId) {
                        // Update the valueStore state
                        return { ...field, value: newValue };
                    }
                    return field;
                });
                return { ...section, category_form_feilds: updatedFields };
            }
            return section;
        });

        setDummyData(updated)
    };

    //  handle organization website change
    const handleOrganizationWebsiteChange = (e, fieldId, sectionId) => {

        let newValue = e.target.value;
        // Prevent space as the first character
        if (newValue.startsWith('')) {
            newValue = newValue.trimStart();
        }
        // Update the inputFormData state
        let updatedSections = inputFormData?.sections?.map(section => {
            // Check if this is the section we're updating
            if (section.id === sectionId) {
                let updatedFields = section.category_form_feilds.map(field => {
                    if (field.id === fieldId) {
                        // Update the valueStore state
                        setValueStore(prevValueStore => ({
                            ...prevValueStore,
                            [`${section?.id}-${field?.id}`]: newValue
                        }));

                        return { ...field, value: newValue };
                    }
                    return field;
                });
                return { ...section, category_form_feilds: updatedFields };
            }
            return section;
        });

        setInputFormData({ ...inputFormData, sections: updatedSections });
    };

    const handleMobileNumberChange = (e, fieldId, sectionId) => {
        let inputVal = e.target.value;

        // Remove non-digit characters
        inputVal = inputVal.replace(/\D/g, '');

        // Ensure the input length is not greater than 10
        if (inputVal.length > 10) {
            inputVal = inputVal.slice(0, 10);
        }

        // Ensure the first digit is not 1, 2, 3, 4, or 5
        if (inputVal.length > 0 && ['1', '2', '3', '4', '5'].includes(inputVal[0])) {
            inputVal = inputVal.slice(1);
        }




        let updatedSections = inputFormData?.sections?.map(section => {
            // Check if this is the section we're updating
            if (section.id === sectionId) {
                let updatedFields = section.category_form_feilds.map(field => {
                    if (field.id === fieldId) {
                        // Update the valueStore state
                        setValueStore(prevValueStore => ({
                            ...prevValueStore,
                            [`${section?.id}-${field?.id}`]: inputVal
                        }));

                        return { ...field, value: inputVal };
                    }
                    return field;
                });
                return { ...section, category_form_feilds: updatedFields };
            }
            return section;
        });

        setInputFormData({ ...inputFormData, sections: updatedSections });

        let update = dummyDAta?.map(section => {
            // Check if this is the section we're updating
            if (section.id === sectionId) {
                let updatedFields = section.category_form_feilds.map(field => {
                    if (field.id === fieldId) {

                        return { ...field, value: inputVal };
                    }
                    return field;
                });
                return { ...section, category_form_feilds: updatedFields };
            }
            return section;
        });
        setDummyData(update)







    };


    const handleKeyUp = (field, section) => {
        // url pattern for website input
        let isValid = true;

        //  state validation
        if (field?.id === 5 || field === "both") {


            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please select state"
                }));
                isValid = false;
            } else {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: ""
                }));
            }
        }
        //  distrct validation
        if (field?.id === 6 || field === "both") {


            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please select district"
                }));
                isValid = false;
            } else {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: ""
                }));
            }
        }
        //  city validation
        if (field?.id === 7 || field === "both") {


            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please select city"
                }));
                isValid = false;
            } else {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: ""
                }));
            }
        }


        // Plot validation
        if (field?.id === 8 || field === "both") {
            if (!valueStore[`${section?.id}-${field?.id}`]) {

                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please enter your plot no / street no"
                }));

                isValid = false;

            } else {

                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: ""
                }));

            }
        }

        // Plot validation
        if (field?.id === 9 || field === "both") {

            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please enter your plot no / street no"
                }));
                isValid = false;
            }
            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please enter accomodation unit"
                }));
                isValid = false;



            } else {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: ""
                }));
            }
        }


        // organsation website validation
        if (field?.id === 10 || field === "both") {


            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please enter organization website"
                }));
                isValid = false;
            } else {
                // Regular expression for URL validation
                const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;

                if (!urlPattern.test(valueStore[`${section?.id}-${field?.id}`])) {

                    setInputFieldErrorMessage((prevErrors) => ({
                        ...prevErrors,
                        [`${section?.id}-${field?.id}`]: "Please enter a valid organization website"
                    }));
                    isValid = false;
                }
                else {

                    setInputFieldErrorMessage((prevErrors) => ({
                        ...prevErrors,
                        [`${section?.id}-${field?.id}`]: ""
                    }));
                }
            }
        }


        // registration authority validation
        if (field?.id === 11 || field === "both") {

            // if (!valueStore[`${section?.id}-${field?.id}`] ) {
            //     setInputFieldErrorMessage((prevErrors) => ({
            //         ...prevErrors,
            //         [`${section?.id}-${field?.id}`]: "Please enter organization website"
            //     }));
            //     isValid = false;
            // }
            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please enter registration authority"
                }));
                isValid = false;



            } else {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: ""
                }));
            }
        }

        // competent authority validation
        if (field?.id === 23 || field === "both") {

            // if (!valueStore[`${section?.id}-${field?.id}`] ) {
            //     setInputFieldErrorMessage((prevErrors) => ({
            //         ...prevErrors,
            //         [`${section?.id}-${field?.id}`]: "Please enter registration authority"
            //     }));
            //     isValid = false;
            // }
            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please enter competent authority"
                }));
                isValid = false;
            } else {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: ""
                }));
            }
        }


        //  number in owner section validation
        if (field?.id === 12 || field === "both") {

            // if (!valueStore[`${section?.id}-${field?.id}`] ) {
            //     setInputFieldErrorMessage((prevErrors) => ({
            //         ...prevErrors,
            //         [`${section?.id}-${field?.id}`]: "Please enter competent authority"
            //     }));
            //     isValid = false;
            // }
            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please enter No of owner"
                }));
                isValid = false;
            } else {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: ""
                }));
            }
        }
        //  owner name validation
        if (field?.id === 13 || field === "both") {

            // if (!valueStore[`${section?.id}-${field?.id}`]) {
            //     setInputFieldErrorMessage((prevErrors) => ({
            //         ...prevErrors,
            //         [`${section?.id}-${field?.id}`]: "Please enter No of owner"
            //     }));
            //     isValid = false;
            // }
            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please enter owner name"
                }));
                isValid = false;
            } else {

                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: ""
                }));
            }
        }
        //  owner email validation
        if (field?.id === 14 || field === "both") {


            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please enter owner email"
                }));
                isValid = false;
            }
            else if (valueStore[`${section?.id}-${field?.id}`].length > 60) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Email must be less than 60 characters."
                }));
                isValid = false;

            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(valueStore[`${section?.id}-${field?.id}`])) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please enter a valid email address."
                }));
                isValid = false;
            }
            else {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: ""
                }));
            }

        }
        //  owner mobile number validation
        if (field?.id === 15 || field === "both") {

            // if (!valueStore[14]) {
            //     setInputFieldErrorMessage((prevErrors) => ({
            //         ...prevErrors,
            //         [`${section?.id}-${14}`]: "Please enter owner email"
            //     }));
            //     isValid = false;
            // }
            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please enter owner mobile number"
                }));
                isValid = false;
            }

            else {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: ""
                }));
            }
        }
        //  owner house  number validation
        if (field?.id === 20 || field === "both") {

            // if (!valueStore[`${section?.id}-${field?.id}`]) {
            //     setInputFieldErrorMessage((prevErrors) => ({
            //         ...prevErrors,
            //         [`${section?.id}-${field?.id}`]: "Please select city"
            //     }));
            //     isValid = false;
            // }
            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please enter owner house number"
                }));
                isValid = false;
            } else {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: ""
                }));
            }
        }
        //  owner land mark validation
        if (field?.id === 21 || field === "both") {

            // if (!valueStore[`${section?.id}-${field?.id}`]) {
            //     setInputFieldErrorMessage((prevErrors) => ({
            //         ...prevErrors,
            //         [`${section?.id}-${field?.id}`]: "Please enter owner house number"
            //     }));
            //     isValid = false;
            // }
            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please enter landmark"
                }));
                isValid = false;
            } else {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: ""
                }));
            }
        }


        //  owner land mark validation
        if (field?.id === 22 || field === "both") {

            // if (!valueStore[`${section?.id}-${field?.id}`]) {
            //     setInputFieldErrorMessage((prevErrors) => ({
            //         ...prevErrors,
            //         [`${section?.id}-${field?.id}`]: "Please enter landmark"
            //     }));
            //     isValid = false;
            // }
            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please upload image"
                }));
                isValid = false;
            } else {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: ""
                }));
            }
        }
        //  state validation
        if (field?.id === 17 || field === "both") {


            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please select state"
                }));
                isValid = false;
            } else {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: ""
                }));
            }
        }
        //  distrct validation
        if (field?.id === 18 || field === "both") {

            // if (!valueStore[`${section?.id}-${field?.id}`]) {
            //     setInputFieldErrorMessage((prevErrors) => ({
            //         ...prevErrors,
            //         [`${section?.id}-${field?.id}`]: "Please select state"
            //     }));
            //     isValid = false;
            // }
            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please select district"
                }));
                isValid = false;
            } else {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: ""
                }));
            }
        }
        //  city validation
        if (field?.id === 19 || field === "both") {

            // if (!valueStore[`${section?.id}-${field?.id}`]) {
            //     setInputFieldErrorMessage((prevErrors) => ({
            //         ...prevErrors,
            //         [`${section?.id}-${field?.id}`]: "Please select district"
            //     }));
            //     isValid = false;
            // }
            if (!valueStore[`${section?.id}-${field?.id}`]) {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: "Please select city"
                }));
                isValid = false;
            } else {
                setInputFieldErrorMessage((prevErrors) => ({
                    ...prevErrors,
                    [`${section?.id}-${field?.id}`]: ""
                }));
            }
        }

        return isValid;

    }
    //  handle next
    const handleSubmit = async () => {

        let isValid = true;
        if (!numPartners) {
            const filterinput = inputFormData?.sections?.filter((i) => i?.section_key !== "owners_partners"
            )
            filterinput?.forEach(section => {
                section.category_form_feilds.forEach(field => {
                    if (!handleKeyUp(field, section)) {
                        isValid = false;
                    }
                });
            });
        } else {
            const filterinput = inputFormData?.sections?.filter((i) => i?.section_key !== "owners_partners"
            )
            filterinput?.forEach(section => {
                section.category_form_feilds.forEach(field => {
                    if (!handleKeyUp(field, section)) {
                        isValid = false;
                    }
                });
            });
        }
        if (dummyDAta?.length) {
            dummyDAta.forEach(section => {
                section.category_form_feilds.forEach(field => {
                    if (!handleKeyUp(field, section)) {
                        isValid = false;
                    }
                });
            });
        }

        if (isValid) {
            try {
                const sectionData = { ...inputFormData?.sections };
                const filterOrganizationSpecifications = inputFormData?.sections?.find((section) => section?.section_key === "organization_specifications");
                const filterSpecifications = inputFormData?.sections?.map((section) => {
                    if (section?.section_key === "organization_specifications") {
                        // Omitting category_form_feilds from the section object
                        const { category_form_feilds, ...rest } = section;
                        return rest;
                    }
                    return section;
                });



                const section = inputFormData?.sections?.find((i) => i?.section_key === "partners_details");
                const { category_form_feilds, ...filterOwnerListMain } = section;
                const filterOtherOrganizationSpecifications = inputFormData?.sections?.filter((i) => i?.section_key !== "organization_specifications");
                const filterOtherOwnerPartner = inputFormData?.sections?.filter((i) => i?.section_key === "owners_partners");

                const organizationDetails = {
                    title: filterOrganizationSpecifications?.section_key || ""
                };

                filterOrganizationSpecifications?.category_form_feilds?.forEach(field => {
                    organizationDetails[field.input_field_name] = field.value;
                });
                const mappingArray = filterOtherOwnerPartner?.map((i) => {
                    const subsectionsObject = {};
                    i?.category_form_feilds?.forEach((field) => {
                        subsectionsObject[field.input_field_name] = field.value;
                    });
                    return { subsections: subsectionsObject };
                });


                const data = {
                    total_count: numPartners,
                    category_id: getLoginSubCabData?.parent_category_id,
                    subCategory_id: getLoginSubCabData?.id,
                    tab_id: tabActiveData?.id,
                    organization_details: { ...filterSpecifications?.[0], ...organizationDetails },
                    main_section: [
                        {
                            sections_details: filterOwnerListMain,
                            sections: mappingArray
                        }
                    ]


                };
                const option = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json', // Correct content type for JSON payload
                        'access-control-allow-origin': '*',
                        "authorization": `Bearer ${token}`

                    },
                    url: `${Apiurl()}/user/application/create`,
                    data: JSON.stringify(data), // Stringify the combined data
                };

                const response = await axios(option);
                if (response) {
                    setDummyData([]);
                    let index = tabsData?.tabs.findIndex(tab => tab.id === tabActiveData.id);
                    if (index !== -1 && index + 1 < tabsData?.tabs.length) {
                        // Next object after tabActiveData
                        let nextTabData = tabsData?.tabs[index + 1];
                        setTabActiveData(nextTabData);

                        // You can now use nextTabData as needed
                    }

                }


            } catch (error) {
                console.error(error);
            }
        }



    }


    return (
        <Dashboard>
            {
                registerMessage && <div className='dc-reg-msg'> Registered Successfully. <span onClick={() => setRegisterMessage(false)}><BsXLg /></span></div>
            }
            <div className='aod-outer'>
                <div className="aod-inner">
                    <div className="aod-top">
                        {tabsData?.map((tab) => {
                            return (

                                <button className={tabActiveData?.id === tab?.id ? 'green' : 'white'} onClick={() => { setTabActiveData(tab); navigate(`/application/${tab?.tableName?.replace(/_/g, '-')}`) }}><AccomodationIcon />{tab?.form_name_en}</button>
                            )
                        })}

                    </div>
                    <div className="aod-bottom" >
                        {inputFormData?.sections.filter((section) => section.section_key !== 'owners_partners') // Filter out the third section
                            .map((section) => (
                                <>
                                    <div className="aod-head">{section?.name_en}</div>
                                    <div className="row py-4">
                                        {section.category_form_feilds?.map(field => (
                                            <div className="col-md-4" key={field.id}>
                                                <div className='suf-input-box aod-form-input mb-2'>
                                                    <label className='mb-1 w-100'>{field.name_en}</label>
                                                    {field?.type === 'text' ?
                                                        <>
                                                            <input
                                                                className='w-100'
                                                                // onChange={(e) => handleFieldChange(e, item?.id)}
                                                                value={field?.value || " "}
                                                                onKeyUp={() => handleKeyUp(field, section)}


                                                                placeholder={`Enter ${field.name_en}`}
                                                                type="text"
                                                                name={field.input_field_name}
                                                                onChange={(e) => {
                                                                    if (field.id === 12) {
                                                                        handlePartnersChange(e, field?.id, section?.id);
                                                                    } else if (field.id === 8) {
                                                                        handleHouseNumberChange(e, field?.id, section?.id);
                                                                    } else if (field.id === 10) {
                                                                        handleOrganizationWebsiteChange(e, field?.id, section?.id);
                                                                    } else {
                                                                        handleFieldChange(e, field?.id, section?.id);
                                                                    }
                                                                }}
                                                            />
                                                            {inputFieldErrorMessage[`${section.id}-${field.id}`] && (
                                                                <p className='validate-error'>{inputFieldErrorMessage[`${section.id}-${field.id}`]}</p>
                                                            )}
                                                        </> : null

                                                    }
                                                    {field.type === 'dropdown' &&
                                                        (field?.id === 5 ? (
                                                            <>
                                                                <Select
                                                                    className='menu_open_select'
                                                                    classNamePrefix='menu_open_select_class'
                                                                    value={field?.value ? { value: field?.value, label: statesData?.find(item => item.id === field?.value)?.name } : null}
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => handleDropdownChangeFirst(e, "state_id", field?.id, section?.id)}
                                                                    options={statesData?.map((state) => ({ label: state.name, value: state.id }))}
                                                                    onMenuOpen={() => {
                                                                        setClickDropdown({ ...clickDropdown, state_id: true })


                                                                        // if (!valueStore[`${section?.id}-${field?.id}`]) {
                                                                        //     setInputFieldErrorMessage((prevErrors) => ({ ...prevErrors, state_id: "Please select State" }));

                                                                        // }
                                                                        if (!valueStore[`${section?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${section.id}-${field?.id}`]: "Please select state"
                                                                            }));

                                                                        }
                                                                    }
                                                                    }
                                                                    placeholder="Select State"
                                                                />
                                                                {inputFieldErrorMessage[`${section.id}-${field.id}`] && (
                                                                    <p className='validate-error'>{inputFieldErrorMessage[`${section.id}-${field.id}`]}</p>
                                                                )}
                                                            </>
                                                        ) : field?.id === 6 ? (
                                                            <>
                                                                <Select
                                                                    className='menu_open_select'
                                                                    classNamePrefix='menu_open_select_class'
                                                                    value={field?.value ? { value: field?.value, label: districtsBySection[section.id]?.find(item => item.id === field?.value)?.name } : null}
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => handleDropdownChangeFirst(e, "district_id", field?.id, section?.id)}
                                                                    onMenuOpen={() => {
                                                                        setClickDropdown({ ...clickDropdown, district_id: true })

                                                                        if (!valueStore[`${section?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${section.id}-${field?.id}`]: "Please select district"
                                                                            }));

                                                                        }
                                                                        if (!valueStore[`${section?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${section.id}-${field?.id}`]: "Please select state"
                                                                            }));

                                                                        }

                                                                    }
                                                                    }
                                                                    options={districtsBySection[section.id]?.map((district) => ({ label: district.name, value: district.id }))}
                                                                    placeholder="Select District"
                                                                />
                                                                {inputFieldErrorMessage[`${section.id}-${field.id}`] && (
                                                                    <p className='validate-error'>{inputFieldErrorMessage[`${section.id}-${field.id}`]}</p>
                                                                )}
                                                            </>
                                                        ) : field?.id === 7 ? (
                                                            <>
                                                                <Select
                                                                    className='menu_open_select'
                                                                    classNamePrefix='menu_open_select_class'
                                                                    value={field?.value ? { value: field?.value, label: citiesBySection[section.id]?.find(item => item.id === field?.value)?.name } : null}

                                                                    aria-label="Default select example"
                                                                    onMenuOpen={() => {
                                                                        setClickDropdown({ ...clickDropdown, city_id: true })

                                                                        if (!valueStore[`${section?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${section.id}-${field?.id}`]: "Please select city"
                                                                            }));

                                                                        }
                                                                        if (!valueStore[`${section?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${section.id}-${field?.id}`]: "Please select district"
                                                                            }));

                                                                        }

                                                                    }
                                                                    }
                                                                    onChange={(e) => handleDropdownChangeFirst(e, "city_id", field?.id, section?.id)}
                                                                    options={citiesBySection[section.id]?.map((city) => ({ label: city.name, value: city.id }))}

                                                                    placeholder="Select City"
                                                                />
                                                                {inputFieldErrorMessage[`${section.id}-${field.id}`] && (
                                                                    <p className='validate-error'>{inputFieldErrorMessage[`${section.id}-${field.id}`]}</p>
                                                                )}
                                                            </>
                                                        ) : null
                                                        )
                                                    }
                                                    {field.type === 'file' && (
                                                        <>
                                                            <input type="file"
                                                                name={field.input_field_name}
                                                                id="profileimg"
                                                                className="w-100 suf-input-field-image"


                                                            />
                                                            <label htmlFor="profileimg" className="suf-input-upload-image"
                                                            >
                                                                <h5 className="para-parts-upload-image">
                                                                    Choose Profile Photo
                                                                </h5>
                                                            </label>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ))}
                        {dummyDAta?.map(item => (
                            <>
                                <div className="aod-head">{item?.name_en}</div>
                                <div className="row py-4">
                                    {item.category_form_feilds?.map(field => {

                                        return (
                                            <div className="col-md-4" key={field.id}>
                                                <div className='suf-input-box aod-form-input mb-2'>
                                                    <label className='mb-1 w-100'>{field.name_en}</label>
                                                    {field.type === 'text' && (
                                                        <>
                                                            <input
                                                                className='w-100'
                                                                // onChange={(e) => handleFieldChange(e, item?.id)}
                                                                value={field?.value || " "}
                                                                onKeyUp={() => handleKeyUp(field, item)}

                                                                onChange={(e) => {
                                                                    if (field.id === 14) {

                                                                        handleEmailChange(e, field?.id, item?.id);

                                                                    }
                                                                    else if (field.id === 15) {
                                                                        handleMobileNumberChange(e, field?.id, item?.id);
                                                                    }

                                                                    else if (field.id === 20) {
                                                                        handleHouseNumberChange(e, field?.id, item?.id);
                                                                    }

                                                                    else {
                                                                        handleFieldChange(e, field?.id, item?.id);
                                                                    }
                                                                }}


                                                                placeholder={`Enter ${field.name_en}`}
                                                                type="text"
                                                                name={field.input_field_name}

                                                            />
                                                            {inputFieldErrorMessage[`${item.id}-${field.id}`] && (
                                                                <p className='validate-error'>{inputFieldErrorMessage[`${item.id}-${field.id}`]}</p>
                                                            )}

                                                        </>
                                                    )}
                                                    {field.type === 'dropdown' &&
                                                        (field?.id === 17 ? (
                                                            <>
                                                                <Select
                                                                    className='menu_open_select'
                                                                    classNamePrefix='menu_open_select_class'

                                                                    value={field?.value ? { value: field?.value, label: statesData?.find(item => item.id === field?.value)?.name } : null}
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => handleDropdownChangeFirst(e, "partner_state_id", field?.id, item?.id)}
                                                                    options={statesData?.map((state) => ({ label: state.name, value: state.id }))}
                                                                    placeholder="Select State"
                                                                    onMenuOpen={() => {
                                                                        setClickDropdown({ ...clickDropdown, partner_state_id: true })


                                                                        if (!valueStore[`${item?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${item.id}-${field?.id}`]: "Please select state"
                                                                            }));

                                                                        }
                                                                    }
                                                                    }
                                                                />
                                                                {inputFieldErrorMessage[`${item.id}-${field.id}`] && (
                                                                    <p className='validate-error'>{inputFieldErrorMessage[`${item.id}-${field.id}`]}</p>
                                                                )}
                                                            </>
                                                        ) : field?.id === 18 ? (
                                                            <>
                                                                <Select
                                                                    className='menu_open_select'
                                                                    classNamePrefix='menu_open_select_class'
                                                                    value={field?.value ? { value: field?.value, label: districtsBySection[item.id]?.find(item => item.id === field?.value)?.name } : null}
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => handleDropdownChangeFirst(e, "partner_district_id", field?.id, item?.id)}
                                                                    options={districtsBySection[item.id]?.map((district) => ({ label: district.name, value: district.id }))}
                                                                    placeholder="Select District"
                                                                    onMenuOpen={() => {
                                                                        setClickDropdown({ ...clickDropdown, partner_state_id: true })


                                                                        if (!valueStore[`${item?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${item.id}-${field?.id}`]: "Please select district"
                                                                            }));

                                                                        }
                                                                        if (!valueStore[`${item?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${item.id}-${field?.id}`]: "Please select state"
                                                                            }));

                                                                        }
                                                                    }
                                                                    }
                                                                />
                                                                {inputFieldErrorMessage[`${item.id}-${field.id}`] && (
                                                                    <p className='validate-error'>{inputFieldErrorMessage[`${item.id}-${field.id}`]}</p>
                                                                )}
                                                            </>
                                                        ) : field?.id === 19 ? (
                                                            <>
                                                                <Select
                                                                    className='menu_open_select'
                                                                    classNamePrefix='menu_open_select_class'
                                                                    value={field?.value ? { value: field?.value, label: citiesBySection[item.id]?.find(item => item.id === field?.value)?.name } : null}
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => handleDropdownChangeFirst(e, "partner_city_id", field?.id, item?.id)}
                                                                    placeholder="Select City"
                                                                    options={citiesBySection[item.id]?.map((city) => ({ label: city.name, value: city.id }))}
                                                                    onMenuOpen={() => {
                                                                        setClickDropdown({ ...clickDropdown, partner_state_id: true })


                                                                        if (!valueStore[`${item?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${item.id}-${field?.id}`]: "Please select city"
                                                                            }));

                                                                        }
                                                                        if (!valueStore[`${item?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${item.id}-${field?.id}`]: "Please select district"
                                                                            }));

                                                                        }
                                                                    }
                                                                    }

                                                                />
                                                                {inputFieldErrorMessage[`${item.id}-${field.id}`] && (
                                                                    <p className='validate-error'>{inputFieldErrorMessage[`${item.id}-${field.id}`]}</p>
                                                                )}
                                                            </>
                                                        ) : null
                                                        )
                                                    }
                                                    {field.id === 22 && (

                                                        <>

                                                            <input type="file"
                                                                name={field.input_field_name}
                                                                id="profileimg"
                                                                className="w-100 suf-input-field-image"
                                                                onChange={(e) => handleImageUpload(e, field?.id, item?.id)}


                                                            />

                                                            <label htmlFor="profileimg" className="od-input-upload-image"
                                                                onClick={() => setImageData(item?.id)}
                                                            >
                                                                <div className='od-image-upload-div'>
                                                                    <UploadIconPhoto />
                                                                </div>
                                                                <h5 className="para-parts-upload-image">
                                                                    Choose Photo
                                                                </h5>
                                                            </label>
                                                            {inputFieldErrorMessage[`${item.id}-${field.id}`] && (
                                                                <p className='validate-error'>{inputFieldErrorMessage[`${item.id}-${field.id}`]}</p>
                                                            )}
                                                            {/* {imageMessage[`${item.id}-${field.id}`] && (
                                                                <p className='validate-image'>{imageMessage[`${item.id}-${field.id}`]}</p>
                                                            )} */}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }


                                    )}
                                </div>
                            </>
                        ))}
                        <div className='od-butt'>
                            {/* <button className='od-das-butt' >Dashboard</button> */}
                            <button className='od-Nex-butt' onClick={handleSubmit}>
                                Next
                            </button>
                        </div>

                    </div>

                </div>

            </div>
        </Dashboard>
    )
}

export default OrganizationDetails
