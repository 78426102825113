export const MandatoryDocumentIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="34" viewBox="0 0 40 34" fill="none">
      <path d="M12.0169 17.0007H19.6829M12.0169 20.2388H22.7493M12.0169 23.4769H16.6165M27.3489 25.0959V13.7626L19.6829 5.66736H12.0169C11.2036 5.66736 10.4236 6.00851 9.84857 6.61577C9.27351 7.22304 8.95044 8.04666 8.95044 8.90545V25.0959C8.95044 25.9547 9.27351 26.7784 9.84857 27.3856C10.4236 27.9929 11.2036 28.334 12.0169 28.334H24.2825C25.0958 28.334 25.8757 27.9929 26.4508 27.3856C27.0259 26.7784 27.3489 25.9547 27.3489 25.0959Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.6843 5.66736V10.5245C19.6843 11.3833 20.0074 12.2069 20.5825 12.8142C21.1575 13.4214 21.9375 13.7626 22.7507 13.7626H27.3504" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
export const CheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path d="M20.8333 12.3229C20.5571 12.3229 20.2921 12.4327 20.0968 12.628C19.9014 12.8234 19.7917 13.0883 19.7917 13.3646V19.1667C19.7917 19.3324 19.7258 19.4914 19.6086 19.6086C19.4914 19.7258 19.3324 19.7917 19.1667 19.7917H5.83333C5.66757 19.7917 5.5086 19.7258 5.39139 19.6086C5.27418 19.4914 5.20833 19.3324 5.20833 19.1667V5.83333C5.20833 5.66757 5.27418 5.5086 5.39139 5.39139C5.5086 5.27418 5.66757 5.20833 5.83333 5.20833H15.8021C16.0784 5.20833 16.3433 5.09859 16.5387 4.90324C16.734 4.70789 16.8438 4.44293 16.8438 4.16667C16.8438 3.8904 16.734 3.62545 16.5387 3.4301C16.3433 3.23475 16.0784 3.125 15.8021 3.125H5.83333C5.11588 3.12774 4.4286 3.41397 3.92128 3.92128C3.41397 4.4286 3.12774 5.11588 3.125 5.83333V19.1667C3.12774 19.8841 3.41397 20.5714 3.92128 21.0787C4.4286 21.586 5.11588 21.8723 5.83333 21.875H19.1667C19.8841 21.8723 20.5714 21.586 21.0787 21.0787C21.586 20.5714 21.8723 19.8841 21.875 19.1667V13.3646C21.875 13.0883 21.7653 12.8234 21.5699 12.628C21.3746 12.4327 21.1096 12.3229 20.8333 12.3229Z" fill="var(---main-theme-color)" />
      <path d="M11.1662 11.4583C10.9707 11.2826 10.716 11.1872 10.4531 11.1913C10.1903 11.1955 9.93872 11.2989 9.74891 11.4808C9.5591 11.6627 9.4451 11.9096 9.42975 12.1721C9.41441 12.4345 9.49887 12.6931 9.66619 12.8958L11.9787 15.3229C12.0755 15.4245 12.1919 15.5055 12.3207 15.5609C12.4496 15.6164 12.5884 15.6453 12.7287 15.6458C12.8683 15.6467 13.0066 15.6194 13.1354 15.5657C13.2643 15.5121 13.381 15.433 13.4787 15.3333L20.5412 8.04168C20.6369 7.94319 20.7124 7.8268 20.7631 7.69916C20.8139 7.57152 20.8391 7.43513 20.8371 7.29778C20.8352 7.16043 20.8062 7.0248 20.7519 6.89864C20.6975 6.77248 20.6189 6.65827 20.5204 6.56251C20.4219 6.46676 20.3055 6.39134 20.1778 6.34056C20.0502 6.28979 19.9138 6.26465 19.7765 6.26658C19.6391 6.26852 19.5035 6.29749 19.3773 6.35184C19.2512 6.40619 19.137 6.48485 19.0412 6.58335L12.7391 13.1042L11.1662 11.4583Z" fill="var(---main-theme-color)" />
    </svg>
  )
}
export const CircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_318_1403)">
        <path d="M9.375 13.1641V3.75C9.375 3.39844 9.64844 3.125 10 3.125C10.3516 3.125 10.625 3.39844 10.625 3.75V13.1641H9.375ZM9.375 14.3945H10.625V15.5664H9.375V14.3945ZM0 10C0 4.47266 4.47266 0 10 0C15.5273 0 20 4.47266 20 10C20 15.5273 15.5273 20 10 20C4.47266 20 0 15.5273 0 10ZM18.7695 10C18.7695 5.17578 14.8242 1.25 10 1.25C5.17578 1.25 1.25 5.17578 1.25 10C1.25 14.8242 5.17578 18.75 10 18.75C14.8242 18.75 18.7695 14.8242 18.7695 10Z" fill="var(---main-theme-color)" />
      </g>
      <defs>
        <clipPath id="clip0_318_1403">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}