/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './AdminDashboard.css';
import SideMenu from './SideMenu';
import { useNavigate } from 'react-router-dom';
import { DashboardBellIcon, DashboardToogleIcon } from '../../../components/Dashboard/DashboardIcons';
import { NavDropdown } from 'react-bootstrap';
import { ClearLocalStorage, GetLocalStorage, GetLocalStorageParsed } from '../../../Utils/Utils';

const AdminDashboard = ({ children }) => {
  const navigate = useNavigate()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const getTokenExpiry = GetLocalStorageParsed("tokenExpiry");
  const token = GetLocalStorage("userInfo");

  useEffect(() => {
    if (!token) {
      navigate("/")
    }
  }, [])
  useEffect(() => {
    if (getTokenExpiry) {
      if (new Date(getTokenExpiry) < new Date()) {

        navigate("/")
      }
    }

  }, [getTokenExpiry])

  const handleLogout = (e) => {
    if (e === "image") {
      navigate("/login")
      ClearLocalStorage();
    } else {
      ClearLocalStorage();
      navigate("/")
    }
  }
  useEffect(() => {
    const checkViewportSize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(true);
      }
    };

    checkViewportSize();

    window.addEventListener('resize', checkViewportSize);

    return () => window.removeEventListener('resize', checkViewportSize);
  }, []);

  return (
    <div className="layout">
      <div className='dh-cont fixed-top' id="AdminDashRight">
        <nav className="header_cont navbar navbar-expand navbar-light sticky-top">
          <div className='logo-toggler-section'>
            <img src={"https://utdbstg.dilonline.in/assets/images/admin/website_logo_1718344813.png"} alt='logo' onClick={() => handleLogout()} className='logo-image' />
            <button className="sidebar-toggle" onClick={toggleSidebar}>
              <DashboardToogleIcon />
            </button>
          </div>

          <div className='main-section-userAvatar'>
            <DashboardBellIcon />
            <NavDropdown title="Hi! Admin" id="basic-nav-dropdown" className='dh-nav-dropdown'>
              <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
            </NavDropdown>
          </div>
        </nav>
      </div>
      <div id="AdminDash" className={isSidebarOpen === true ? 'd-flex dash-main-div dash-main-coll pt-5 mt-5' : " d-flex dash-main-div pt-5 mt-5"}>
        <div  className={`p-0 sidePanel ${isSidebarOpen === true ? 'ds-cont ds-cont-coll' : "ds-cont"}`}>
          <SideMenu />
        </div>
        <div className={`col-md-12`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
