import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { DeleteIcon, EditIcon } from '../AdminIcon'
import { Modal, Table } from 'react-bootstrap'
import { Apiurl, GetLocalStorage } from '../../../Utils/Utils'
import axios from 'axios'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useNavigate } from 'react-router-dom'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { HiPlus } from 'react-icons/hi'
import { deleteRegistrationFieldData, getRegistrationFieldsListData } from '../../../services/api/admin'

const RegistrationForm = () => {
    const token = GetLocalStorage("userInfo")
    const navigate = useNavigate()
    const [registrationFields, setRegistrationFields] = useState([])
    const [deleteData, setDeleteData] = useState()
    //button data
    const [buttData, setButtData] = useState([]);
    const [activeTab, setActiveTab] = useState(1)

    //registration fields list
    const getRegistrationFieldsList = async () => {
        try {
            const registrationFieldData = await getRegistrationFieldsListData()
            const data = registrationFieldData?.data
            setRegistrationFields(data)
            setButtData([...data?.button_fields, ...data?.form_fields]);
        } catch (err) {
            console.log("Registration field list Error: ", err)
            navigate("/")
        }
    };
    useEffect(() => {
        getRegistrationFieldsList()
    }, [])

    // delete sub-Category
    const deleteRegistrationField = async (item) => {
        try {
            const formData = new FormData();
            formData.append("field_id", item?.id)
            const response = await deleteRegistrationFieldData(formData)
            getRegistrationFieldsList()
            setDeleteData("")()
        } catch (error) {
            console.log("Registration field list delete Error: ", error)
            navigate("/")
        }
    }

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                <div className='ap-breadcrumb pt-4 pb-2 mb-4 adm-breadcrumb'>
                    <div>
                        <HomeIcon />Home  /  <span>Registration Form</span>
                    </div>
                    <button className='adm-tab-btn' onClick={() => navigate("/admin/registration/add")}><span><HiPlus />
                    </span> Add Registration Field</button>
                </div>
                <div className="aod-inner pt-0">
                    <div className="aod-bottom">
                        <div className="adm-tabs-outer">
                            <button className={activeTab == 1 ? "active-adm-tab btn-first" : "inactive-adm-tab btn-first"} onClick={() => setActiveTab(1)}>Registration Field List </button>
                            <button className={activeTab == 2 ? "active-adm-tab btn-last" : "inactive-adm-tab btn-last"} onClick={() => setActiveTab(2)}>Register Form Buttons </button>
                        </div>
                        <div className="aod-head"></div>
                        <div className="ad-reg-table">
                            {
                                activeTab == 1 ? <Table size="sm" className='ad-cat-table mt-4'>
                                    <thead>
                                        <tr>
                                            <th className='ad-sno'>S No</th>
                                            <th className='ad-long'>English Name</th>
                                            <th className='ad-long'>Hindi Name</th>
                                            <th className='ad-long'>Input Type</th>
                                            <th className='ad-sno'>Status</th>
                                            <th className='ad-long'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            registrationFields?.input_fields?.filter(i => i?.status == "1")?.map((item, index) => {
                                                return (
                                                    <tr key={index} className={index % 2 != 0 ? "adm-even" : ""}>
                                                        <td>{index + 1}</td>
                                                        <td>{item?.name_en ? item?.name_en : "--"}</td>
                                                        <td>{item?.name_hi ? item?.name_hi : "--"}</td>
                                                        <td>{item?.type ? item?.type : "--"}</td>
                                                        <td>{item?.required == "1" ? "Active" : "Inactive"}</td>
                                                        <td><span className='ad-cat-edit' onClick={() => { navigate("/admin/registration/update", { state: item }) }}><EditIcon /></span><span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span></td>
                                                    </tr>
                                                )
                                            }
                                            )
                                        }
                                    </tbody>
                                </Table> : activeTab == 2 ? <Table size="sm" className='ad-cat-table mt-4'>
                                    <thead>
                                        <tr>
                                            <th className='ad-sno'>S No</th>
                                            <th className='ad-long'>English Name</th>
                                            <th className='ad-long'>Hindi Name</th>
                                            <th className='ad-long'>Type</th>
                                            <th className='ad-long'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            buttData?.map((item, index) => (
                                                <tr key={index} className={index % 2 != 0 ? "adm-even" : ""}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.name_en ? item?.name_en : "--"}</td>
                                                    <td>{item?.name_hi ? item?.name_hi : "--"}</td>
                                                    <td>{item?.type ? item?.type : "--"}</td>
                                                    <td><span className='ad-cat-edit' onClick={() => navigate("/admin/registration/update-buttons", { state: item })}><EditIcon /></span></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table> : null
                            }

                        </div>
                    </div>
                </div>



            </div>
            <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you really want to delete this Field ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div className="suf-btn d-flex flex-row gap-3 p-2">
                        <button onClick={() => { deleteRegistrationField(deleteData) }}>Yes</button>
                        <button className='suf-can-butt' onClick={() => setDeleteData("")}>No</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </AdminDashboard>
    )
}

export default RegistrationForm
